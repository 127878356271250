import { NodeType } from "../../../types/NodeType";
import { regexTimestamp } from "./timestamp";

const instance: NodeType = {
  type: "splitTimestamp",
  label: "Separar Tempo",
  description: "Separa um tempo",
  category: "Tempo",
  inputs() {
    return [{ type: "timestamp", name: "timestamp", label: "Tempo" }];
  },
  outputs() {
    return [
      { type: "number", name: "day", label: "Dia" },
      { type: "number", name: "month", label: "Mês" },
      { type: "number", name: "year", label: "Ano" },
      { type: "number", name: "hour", label: "Horas" },
      { type: "number", name: "minute", label: "Minutos" },
      { type: "number", name: "second", label: "Segundos" },
    ];
  },
  async resolveOutputs(inputs) {
    if (!inputs.timestamp)
      return { day: 0, month: 0, year: 0, hour: 0, minute: 0, second: 0 };

    const m = regexTimestamp.exec(inputs.timestamp);
    if (!m) return { day: 0, month: 0, year: 0, hour: 0, minute: 0, second: 0 };

    const d: number = parseInt(m[1]);

    const year = d / 365;
    const month = (d % 365) / 30;
    const day = (d % 365) % 30;
    const hour = parseInt(m[3]);
    const minute = parseInt(m[4]);
    const second = parseInt(m[5]);

    console.log(
      `[Separar tempo] y ${year} m ${month} d ${day} h ${hour} m ${minute} s ${second}`
    );

    return {
      day,
      month,
      year,
      hour,
      minute,
      second,
    };
  },
};

export default instance;
