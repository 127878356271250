import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "orgFind",
  label: "[Tomticket] Procurar organização",
  description: "Procura por uma organização no TomTicket.",
  category: "Tomticket",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "string", name: "token", label: "Token" },
      { type: "string", name: "email", label: "Email" },
      { type: "string", name: "id_cliente", label: "ID cliente" },
      { type: "string", name: "nome_campo", label: "Campo a procurar" },
      { type: "string", name: "valor_campo", label: "Valor a procurar" },
    ];
  },
  outputs() {
    return [
      { type: "string", name: "id", label: "ID" },
      { type: "string", name: "nome", label: "Nome" },
      { type: "route", name: "route-true", label: "Encontrado" },
      { type: "route", name: "route-false", label: "Não encontrado" },
    ];
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: "[Tomticket] Procurar organização", indent });

    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: "(SE encontrado)", indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-false");
    if (nextFalse) {
      stack.push({ result: "(SE não encontrado)", indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }

    stack.push({ result: "(Fim SE)", indent });
  },
};

export default instance;
