import { NodeType } from "../../types/NodeType";

const instance: NodeType = {
  type: "loop",
  label: "Loop",
  // crie uma boa descrição para um nó que percorre os itens de uma lista
  description: "Percorre uma lista de itens",
  category: "Fluxo",
  inputs() {
    const result = [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "list", name: "list", label: "Lista" },
      { type: "route", name: "route-loop", label: "Loop" },
      { type: "portType", name: "portType", label: "Tipo de item" },
    ];

    return result;
  },
  outputs(inputValues) {
    const result: any[] = [];

    if (inputValues?.portType?.id) {
      result.push({
        type: inputValues.portType.id,
        name: "item",
        label: "Item",
      });
    }

    result.push({ type: "number", name: "index", label: "Índice" });
    result.push({ type: "route", name: "route-loop", label: "Loop" });
    result.push({ type: "route", name: "route", label: "Fim loop" });

    return result;
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: `[Loop]`, indent });

    const next = nextNodeId(nodeId, "route-loop");
    if (next) {
      stack.push({ result: "(Loop)", indent });
      stack.push({ id: next, indent: indent + 1 });
    }

    stack.push({ result: "(Fim Loop)", indent });

    const nextEnd = nextNodeId(nodeId, "route");
    if (nextEnd) {
      stack.push({ id: nextEnd, indent });
    }
  },
};

export default instance;
