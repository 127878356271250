import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "addTag",
  label: "Adicionar marcador",
  description:
    "Adiciona um marcador ao chat. Marcadores podem ser vistos no chat e também em relatórios",
  category: "Chat",
  inputs(inputValues) {
    const result = [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "domainTag", name: "domainTag", label: "Marcador" },
    ];

    return result;
  },
  outputs(/* inputValues, context */) {
    return [{ type: "route", name: "route", label: "Saída fluxo" }];
  },
  async resolveOutputs(inputs, outConnections, context, localVars) {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({
      result: `[Adicionar marcador] ${inputValues.domainTag.label}`,
      indent,
    });

    const next = nextNodeId(nodeId, "route");
    if (next) {
      stack.push({ id: next, indent: indent });
    }
  },
};

export default instance;
