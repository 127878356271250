import Botao from '@/components/Botao';
import BotaoPerigoso from '@/components/BotaoPerigoso';
import HBox from '@/components/layout/HBox';
import VBox from '@/components/layout/VBox';
import { mdiDrag, mdiPen, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { VendaDto } from '@tera/shared/src/types/VendaDto';
import React, { CSSProperties } from 'react';
import { Draggable } from 'react-beautiful-dnd';

type CardVendaProps = {
  venda: VendaDto;
  index: number;
  isDragging: boolean;
  canDrag: boolean;
  onEdit: (venda: VendaDto) => void;
  onDelete: (venda: VendaDto) => void;
};

const getItemStyle = (
  isDragging: boolean,
  canDrag: boolean,
  draggableStyle?: CSSProperties,
): CSSProperties => ({
  userSelect: 'none',
  background: isDragging ? 'lightgreen' : undefined,
  border: '1px solid #CCC',
  padding: '8px',
  opacity: canDrag ? 1 : 0.5,

  ...(draggableStyle ?? {}),

  transform: isDragging
    ? 'rotate(5deg) ' + draggableStyle?.transform
    : draggableStyle?.transform,
});

const numFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const CardVenda = ({
  isDragging,
  venda,
  index,
  onEdit,
  onDelete,
  canDrag,
}: CardVendaProps) => {
  const [showControls, setShowControls] = React.useState(false);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleMouseEnter = () => setShowControls(true);
    const handleMouseLeave = () => setShowControls(false);

    const handleClick = (e: MouseEvent) => {
      console.log('click', e.target, showControls);
      setShowControls(!showControls);
    };

    const container = containerRef.current;
    const content = contentRef.current;

    if (content) content.addEventListener('click', handleClick);

    if (container) {
      container.addEventListener('mouseenter', handleMouseEnter);
      container.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (content) content.removeEventListener('click', handleClick);

      if (container) {
        container.removeEventListener('click', handleClick);
        container.removeEventListener('mouseenter', handleMouseEnter);
        container.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [showControls]);

  const handleEdit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onEdit?.(venda);
  };

  const handleDelete = () => {
    onDelete?.(venda);
  };

  return (
    <Draggable
      key={venda.id}
      draggableId={venda.id.toString()}
      index={index}
      isDragDisabled={!canDrag}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            canDrag,
            provided.draggableProps.style,
          )}
        >
          <div
            ref={containerRef}
            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
          >
            <HBox>
              <div
                style={{
                  height: 50,
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                }}
                {...provided.dragHandleProps}
              >
                <Icon path={mdiDrag} size={1} />
              </div>
              <div ref={contentRef} style={{ flex: 1, cursor: 'pointer' }}>
                <VBox>
                  <div>{venda.cliente?.nome}</div>
                  <div>{numFormatter.format(venda.valor_total)}</div>
                </VBox>
              </div>
              <VBox
                style={{
                  maxWidth: '32px',
                  visibility:
                    !isDragging && showControls ? 'visible' : 'hidden',
                }}
              >
                <Botao
                  variant="none-primary"
                  icon={mdiPen}
                  onClick={handleEdit}
                ></Botao>
                <BotaoPerigoso
                  variant="none-danger"
                  icon={mdiTrashCan}
                  onClick={handleDelete}
                ></BotaoPerigoso>
              </VBox>
            </HBox>
          </div>
        </div>
      )}
    </Draggable>
  );
};
