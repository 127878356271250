import CampoSeletor from '@/components/Formulario/CampoSeletor';
import axios from 'axios';
import { useQuery } from 'react-query';

type CampoSeletorProdutoProps = {
  value: number;
  onChange: (v: number, product_value: number) => void;
  label: string;
  error: string | undefined;
};
export const CampoSeletorProduto = ({
  value,
  onChange,
  label,
  error,
}: CampoSeletorProdutoProps) => {
  const q = useQuery('produto/box', async () => {
    const result = await axios.get('/api/produto/box');
    return result.data.result;
  });
  const options =
    q.data?.map((c: any) => ({
      id: c.id,
      label: c.nome,
      valor: c.valor,
    })) ?? [];

  return (
    <CampoSeletor
      options={options}
      value={value?.toString()}
      onChange={v => onChange(+v, options.find((o: any) => o.id == +v)?.valor)}
      label={label}
      error={error}
    />
  );
};
