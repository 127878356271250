import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import {
  mdiLoading,
  mdiMagnify,
  mdiPen,
  mdiPlus,
  mdiStar,
  mdiTrashCan,
} from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import axios from 'axios';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import { FormProduto } from './FormProduto';
import { TextoMetadados } from '@/components/modelos/TextoMetadados';
import { FormMetadados } from '@/components/modelos/FormMetadados';
import { useDebounce } from '../chat/useDebounce';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type usuarioPageProps = {};

const usuarioPage = ({}: usuarioPageProps) => {
  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const [editCustomizeObject, setEditCustomizeObject] =
    React.useState<any>(null);
  const [customizeFieldErrors, setCustomizeFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const customizeMutator = useMutation(
    async () => {
      if (!editCustomizeObject) return;

      setCustomizeFieldErrors(null);

      return await axios.post(`/api/produto/meta`, editCustomizeObject, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        setCustomizeFieldErrors(null);
        setEditCustomizeObject(null);
        queryList.refetch();
        qCustomize.refetch();
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setCustomizeFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const [showCustomizeModal, setShowCustomizeModal] = React.useState(false);
  const qCustomize = useQuery(
    'produto/meta',
    async () => {
      const res = await axios.get('/api/produto/meta', {
        withCredentials: true,
      });

      return res.data.result;
    },
    {
      onSuccess: data => {
        setEditCustomizeObject(data);
      },
      refetchOnWindowFocus: false,
    },
  );

  const handleCustomizeModal = () => {
    setShowCustomizeModal(true);
    customizeMutator.reset();
  };

  const handleCloseCustomizeModal = () => {
    setShowCustomizeModal(false);
    setEditCustomizeObject([]);
    setCustomizeFieldErrors(null);
  };

  const handleChangeCustomization = () => {
    customizeMutator.mutateAsync();
  };

  const headersMeta =
    qCustomize.data
      ?.filter((it: any) => it.listavel)
      ?.map((it: any) => ({
        label: it.rotulo,
        render: (row: any) => {
          return (
            <TextoMetadados
              type={it.tipo}
              value={row.metadados?.[it.nome] ?? '-'}
            />
          );
        },
      })) ?? [];

  const headers: DataTableHeader[] = [
    { label: 'Código', width: '80px', column: 'id' },
    { label: 'Nome', width: '40%', column: 'nome' },
    { label: 'Descrição', width: '60%', column: 'descricao' },
    ...headersMeta,
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            <Botao
              variant="none-primary"
              icon={mdiPen}
              onClick={() => setEditObject(row)}
            />
            <BotaoPerigoso
              variant="none-danger"
              icon={mdiTrashCan}
              onClick={() => handleDelete(row.id)}
            />
          </HBox>
        );
      },
    },
  ];

  const queryList = useInfiniteQuery({
    queryKey: ['produto', debouncedSearch],
    queryFn: async ({ pageParam }) => {
      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
      });

      return await axios.get<ResponseList>(
        `/api/produto?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const changeMutator = useMutation(
    async () => {
      if (!editObject) return;

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(`/api/produto/${editObject.id}`, editObject, {
          withCredentials: true,
        });
      } else {
        return await axios.post(`/api/produto`, editObject, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/produto/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Produto apagado com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar o produto.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate();
  }, [changeMutator]);

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({});
  };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[queryList.data.pages.length - 1]?.data?.total ?? 0;
  const editMode = !!editObject?.id && editObject.id > 0;

  return (
    <LayoutUsuario>
      <Modal
        ignoreOverlayClick
        title={editMode ? 'Editar produto' : 'Novo produto'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormProduto
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>

      <Modal
        size="lg"
        ignoreOverlayClick
        title="Personalizar contatos"
        show={showCustomizeModal}
        onClose={handleCloseCustomizeModal}
        onCancel={handleCloseCustomizeModal}
        onConfirm={handleChangeCustomization}
      >
        <p>Todos os produtos receberão os campos abaixo.</p>

        <FormMetadados
          value={editCustomizeObject}
          onChange={setEditCustomizeObject}
          fieldErrors={customizeFieldErrors}
        />
      </Modal>

      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Produtos</Titulo>
            <Descricao>
              Gerencie aqui os produtos que participam de suas vendas.
            </Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant="outline-primary"
              icon={mdiStar}
              onClick={handleCustomizeModal}
            >
              Personalizar...
            </Botao>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Novo
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <HBox>
          <CampoTexto
            prepend={
              <Icon
                path={mdiMagnify}
                size={1}
                color="var(--tc-color-gray-500)"
              />
            }
            placeholder="Pesquisar"
            value={search}
            onChange={setSearch}
          />
        </HBox>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default usuarioPage;
