import CampoSeletor from '@/components/Formulario/CampoSeletor';
import axios from 'axios';
import { useQuery } from 'react-query';

type CampoSeletorUsuarioProps = {
  value: number;
  onChange: (v: number) => void;
  label: string;
  error: string | undefined;
};
export const CampoSeletorUsuario = ({
  value,
  onChange,
  label,
  error,
}: CampoSeletorUsuarioProps) => {
  const q = useQuery('usuario/box', async () => {
    const result = await axios.get('/api/usuario/box');
    return result.data.result;
  });
  const options = q.data?.map((c: any) => ({ id: c.id, label: c.nome })) ?? [];

  return (
    <CampoSeletor
      options={options}
      value={value?.toString()}
      onChange={v => onChange(+v)}
      label={label}
      error={error}
    />
  );
};
