import { mdiInformationSlabCircleOutline } from '@mdi/js';
import HBox from '../layout/HBox';
import { Tooltip } from '../layout/Tooltip';
import styles from './CampoTexto.module.css';
import Icon from '@mdi/react';
import React, { useEffect } from 'react';

type Props = {
  value?: FileList;
  onChange?: (value: FileList | null) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  error?: string;
  tooltipText?: React.ReactNode;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  noBorder?: boolean;
  [key: string]: any;
};

export default function CampoArquivo({
  value,
  onChange,
  label,
  placeholder,
  required,
  prepend,
  append,
  error,
  tooltipText,
  tooltipPosition,
  noBorder,
  ...props
}: Readonly<Props>) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (value == null && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [value, inputRef.current]);

  return (
    <div style={{ width: '100%' }}>
      <label>
        <HBox stretch>
          <span>{label}</span>
          {tooltipText && (
            <Tooltip text={tooltipText} position={tooltipPosition}>
              <Icon
                path={mdiInformationSlabCircleOutline}
                size="14px"
                color="var(--tc-color-info)"
              />
            </Tooltip>
          )}
        </HBox>
      </label>
      <div
        className={[styles.container, error ? styles.error : null].join(' ')}
        style={{ border: noBorder ? 'none' : undefined }}
      >
        {prepend && <div className={styles.prepend}>{prepend}</div>}
        <input
          {...props}
          ref={inputRef}
          type="file"
          onChange={e => onChange?.(e.target.files)}
          placeholder={placeholder}
          required={required}
        />
        {append && <div className={styles.append}>{append}</div>}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
