import React from 'react';
import styles from './DataTable.module.css';
import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';

export type DataTableHeader = {
  label: string;
  column?: string;
  render?: (row: DataTableRow) => React.ReactNode;
  width?: string;

  stickLeft?: boolean;
  stickRight?: boolean;
  alignRight?: boolean;
  alignCenter?: boolean;
  alignLeft?: boolean;
};

export type DataTableRow = any;

type DataTableProps = {
  isLoading?: boolean;
  cabecalhos: DataTableHeader[];
  linhas?: DataTableRow[];
  onLoadMore?: () => void;
  hasMoreData?: boolean;

  paginas?: any[];
  aoObterPagina?: (pagina: any) => DataTableRow[];
};

const DataTable = ({
  isLoading,
  cabecalhos,
  linhas,
  onLoadMore,
  hasMoreData,
  paginas,
  aoObterPagina,
}: DataTableProps) => {
  const handleScroll = React.useCallback(
    async (e: React.UIEvent<HTMLDivElement>) => {
      const el = e.currentTarget;
      if (
        hasMoreData &&
        !isLoading &&
        el.scrollHeight - el.scrollTop - 100 <= el.clientHeight
      ) {
        onLoadMore?.();
      }
    },
    [isLoading, onLoadMore, hasMoreData],
  );

  const renderLinha = (linha: DataTableRow, lidx: number) => (
    <tr key={`linha-${lidx}`}>
      {cabecalhos.map((it, index) => (
        <td
          className={[
            it.stickLeft
              ? styles.stickLeft
              : it.stickRight
                ? styles.stickRight
                : null,
            it.alignRight
              ? styles.alignRight
              : it.alignCenter
                ? styles.alignCenter
                : it.alignLeft
                  ? styles.alignLeft
                  : null,
          ].join(' ')}
          key={`linha-${lidx}-col-${index}`}
        >
          {it.render ? it.render!(linha) : linha[it.column!]}
        </td>
      ))}
    </tr>
  );

  return (
    <div className={styles.container} onScroll={handleScroll}>
      <table className={styles.table}>
        <thead>
          <tr>
            {cabecalhos?.map((it, lidx) => (
              <th
                key={`header-${lidx}`}
                className={[
                  it.stickLeft
                    ? styles.stickLeft
                    : it.stickRight
                      ? styles.stickRight
                      : null,
                ].join(' ')}
                style={{ width: it.width }}
              >
                {it.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {linhas && linhas.length > 0 && linhas?.map(renderLinha)}
          {paginas &&
            paginas.length > 0 &&
            aoObterPagina &&
            paginas.map(p => {
              const rows = aoObterPagina(p);
              if (!rows) return null;

              return rows.map((r, idx) => renderLinha(r, idx));
            })}

          {!isLoading && !hasMoreData && (
            <tr>
              <td
                colSpan={cabecalhos?.length}
                style={{
                  textAlign: 'center',
                  backgroundColor: 'var(--tc-color-gray-200)',
                }}
              >
                Isso é tudo.
              </td>
            </tr>
          )}
          {isLoading &&
            linhas &&
            linhas.length > 20 &&
            Array.from({ length: 20 }).map((_, idx) => (
              <tr key={idx}>
                <td colSpan={cabecalhos?.length}>
                  <div className={styles.skeletonContainer}>
                    <span
                      className={[styles.skeleton, styles.skeletonA].join(' ')}
                    ></span>
                    <span
                      className={[styles.skeleton, styles.skeletonB].join(' ')}
                    ></span>
                    <span
                      className={[styles.skeleton, styles.skeletonC].join(' ')}
                    ></span>
                  </div>
                </td>
              </tr>
            ))}
          {isLoading && linhas && linhas.length > 0 && (
            <tr>
              <td
                colSpan={cabecalhos?.length}
                style={{
                  textAlign: 'center',
                  backgroundColor: 'var(--tc-color-gray-200)',
                }}
              >
                <Icon
                  path={mdiLoading}
                  size={1}
                  color="var(--tc-color-gray-700)"
                  spin
                />{' '}
                Carregando mais dados...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
