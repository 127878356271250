import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "string2number",
  label: "String para número",
  description: "Converte uma string para número",
  category: "String",
  inputs(inputValues) {
    const ret = [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "string", name: "string", label: "Texto" },
    ];

    const template = inputValues?.string ?? "";
    const re = /\{\{([^\}]+?)\}\}/g;

    const ids: string[] = [];
    let res;
    while ((res = re.exec(template)) !== null) {
      if (!ids.includes(res[1])) ids.push(res[1]);
    }
    if (ids.length)
      ret.push(...ids.map((id) => ({ type: "string", name: id, label: id })));

    return ret;
  },
  outputs() {
    return [
      { type: "number", name: "number", label: "Número" },
      { type: "route", name: "route", label: "Saída fluxo" },
      { type: "route", name: "route-invalido", label: "Texto inválido" },
    ];
  },
  async resolveOutputs(inputs) {
    if (inputs.string === undefined || inputs.string === null)
      return { route: "route-invalido" };
    const num = Number(inputs.string);
    if (!Number.isSafeInteger(num)) return { route: "route-invalido" };

    return { number: num ?? 0 };
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: "[String para número]", indent });

    const next = nextNodeId(nodeId, "route");
    if (next) {
      stack.push({ id: next, indent });
    }
  },
};

export default instance;
