import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "newList",
  label: "Criar lista",
  description: "Cria uma nova lista.",
  category: "Lista",
  inputs() {
    return [{ type: "route", name: "route", label: "Entr. fluxo" }];
  },
  outputs() {
    return [
      { type: "list", name: "list", label: "Lista" },
      { type: "route", name: "route", label: "Saída fluxo" },
    ];
  },
  async resolveOutputs(inputs, outConnections, context, localVars) {
    localVars.list = [];
    return { list: localVars.list };
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: "[Criar lista]", indent });

    const next = nextNodeId(nodeId, "route");
    if (next) {
      stack.push({ id: next, indent });
    }
  },
};

export default instance;
