import { useState, useEffect, useRef, useCallback } from 'react';
import { Socket, io } from 'socket.io-client';
import styles from './page.module.css';
import Mensagem from '../chat/Mensagem';
import { ChatMensagemDto } from '@tera/shared/src/dto/ChatMensagemDto';
import { useLocalStorage } from '../../lib/useLocalStorage';
import { useSearchParams } from 'react-router-dom';
import HBox from '../../components/layout/HBox';
import Icon from '@mdi/react';
import { mdiAccount, mdiCamera, mdiRobot } from '@mdi/js';
import Botao from '../../components/Botao';
import Modal from '@/components/layout/Modal';
import Selfie from '@/components/perfil/Selfie';
import { nanoid } from 'nanoid';

const App = () => {
  const [messages, setMessages] = useState<any[]>([]);
  const [input, setInput] = useState('');
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'sessionId',
    '',
  );
  const [yourName, setYourName] = useLocalStorage<string | null>(
    'yourName',
    '',
  );

  const [onlineAttendants, setOnlineAttendants] = useState<any[]>([]);

  const [error, setError] = useState<string | null>(null);

  const [searchParams] = useSearchParams();

  const apikey = searchParams.get('apikey') ?? '';
  const did = searchParams.get('did') ?? '';
  const auth = searchParams.get('a') ?? '';
  const text = searchParams.get('t') ?? '';

  const [inputName, setInputName] = useState('');

  const socket = useRef<Socket>(
    io('/wswebchat', {
      autoConnect: false,
      withCredentials: true,
      transports: ['websocket'],
      query: { token: sessionId, apikey, did, auth, text },
    }),
  );

  const handleSession = useCallback(
    (data: any) => {
      setSessionId(data.sessionId);
    },
    [setSessionId],
  );

  const handleMessage = useCallback((message: any) => {
    setMessages(prevMessages => [message, ...prevMessages]);
    console.log('new message', message);
  }, []);

  const handleChat = useCallback((chat: any) => {
    if (chat.estado === 'finalizado' && chat.kioskMode) {
      location.reload();
    }
  }, []);

  const handleMessages = useCallback((messages: any) => {
    setMessages(messages);
    console.log('messages', messages);
  }, []);

  const handleError = useCallback((error: any) => {
    switch (error) {
      case 'missing apikey':
        setError(
          'Opa! Lamentamos, mas parece que uma chave de API não foi fornecida.\nPor favor, entre em contato com o suporte.',
        );
        break;
      case 'invalid apikey':
        setError(
          'Opa! Lamentamos, mas parece que a chave de API fornecida é inválida.\nPor favor, entre em contato com o suporte.',
        );
        break;
      case 'unauthorized':
        setError(
          'Opa! Lamentamos, mas você não está autorizado a acessar este chat.\nPor favor, entre em contato com o suporte.',
        );
        break;
      default:
        setError(error);
        break;
    }
  }, []);

  const handleOnline = useCallback((users: any[]) => {
    setOnlineAttendants(users);
  }, []);

  const handleOnlineAdd = useCallback((user: any) => {
    setOnlineAttendants(prev => [...prev, user]);
  }, []);

  const handleOnlineDel = useCallback((uid: number) => {
    setOnlineAttendants(prev => prev.filter(u => u.id !== uid));
  }, []);

  useEffect(() => {
    socket.current.connect();
    const _socket = socket.current;

    if (yourName) {
      _socket.emit('name', yourName);
    }

    return () => {
      _socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const _socket = socket.current;
    _socket.on('session', handleSession);
    _socket.on('chat', handleChat);
    _socket.on('message', handleMessage);
    _socket.on('messages', handleMessages);
    _socket.on('error', handleError);

    _socket.on('online', handleOnline);
    _socket.on('online.add', handleOnlineAdd);
    _socket.on('online.del', handleOnlineDel);

    return () => {
      _socket.off('session', handleSession);
      _socket.off('chat', handleChat);
      _socket.off('message', handleMessage);
      _socket.off('messages', handleMessages);
      _socket.off('error', handleError);

      _socket.off('online', handleOnline);
      _socket.off('online.add', handleOnlineAdd);
      _socket.off('online.del', handleOnlineDel);
    };
  }, [
    handleSession,
    handleMessage,
    handleMessages,
    handleError,
    handleOnline,
    handleOnlineAdd,
    handleOnlineDel,
  ]);

  const handleSendMedia = (file: File) => {
    const message = {
      id: nanoid(),
      attachments: [
        {
          contentId: nanoid(),
          content: file,
          contentType: file.type,
          filename: file.name,
          size: file.size,
        },
      ],
    };
    socket.current.emit('message', message);
    setInput('');
    setSelfie(false);
  };

  const sendMessage = (msg: string) => {
    if (msg.trim()) {
      const message = {
        id: nanoid(),
        text: msg,
      };
      socket.current.emit('message', message);
    }
  };

  const handleSendMessage = useCallback(() => {
    if (input.trim()) {
      sendMessage(input);
      setInput('');
    }
  }, [input, sendMessage]);

  const handleAcceptCookies = () => {
    socket.current.emit('session');
  };

  const handleSendMyName = () => {
    socket.current.emit('name', inputName);
    setYourName(inputName);
  };

  const handleSelectOption = (option: string) => {
    const message = {
      id: nanoid(),
      text: option,
    };
    socket.current.emit('message', message);
  };

  const lastMessage = messages[0];

  const isSendMessageDisabled =
    !input.trim().length ||
    !sessionId ||
    !yourName ||
    !!lastMessage?.opcoes?.length ||
    !!lastMessage?.metadados?.opcoes?.length;

  const isSendMediaDisabled =
    !sessionId ||
    !yourName ||
    !!lastMessage?.opcoes?.length ||
    !!lastMessage?.metadados?.opcoes?.length;

  const options = lastMessage?.metadados?.opcoes ?? lastMessage?.opcoes;

  const [selfie, setSelfie] = useState(false);

  return (
    <div className={styles[`chat-container`]}>
      <div className={styles[`online-attendants`]}>
        <h4>Atendentes online</h4>

        <Modal
          show={selfie}
          onClose={() => setSelfie(false)}
          onCancel={() => setSelfie(false)}
          title="Enviar foto"
          hideControls
        >
          <Selfie active={selfie} onSelfieTaken={handleSendMedia} />
        </Modal>

        <HBox>
          <div>
            <img src="/logo.png" style={{ height: '56px' }} />
          </div>
          <div className={styles[`online-attendants-list`]}>
            <div>
              <Icon path={mdiRobot} size={1} color="var(--tc-color-primary)" />
            </div>
            {onlineAttendants.map(attendant => (
              <>
                <div key={attendant.id}>
                  <HBox>
                    {attendant.thumb ? (
                      <img
                        title={attendant.nome}
                        src={`data:image/png;base64,${attendant.thumb}`}
                      />
                    ) : (
                      <Icon path={mdiAccount} size={1} />
                    )}
                  </HBox>
                </div>
              </>
            ))}
          </div>
        </HBox>
      </div>
      <div className={styles[`messages`]}>
        {messages.map((msg, idx) => (
          <Mensagem
            key={msg.id}
            mensagens={messages}
            index={idx}
            item={msg}
            autor={yourName + ' (você)'}
            onOptionChosen={
              lastMessage.id === msg.id ? handleSelectOption : undefined
            }
            onSelectedKbSection={handleSelectOption}
          />
        ))}
      </div>
      {!sessionId && !yourName && !error && (
        <div className={styles.cookiesMessage}>
          <h4>Cookies!</h4>
          <p>
            Nós usamos cookies para que o chat funcione e você tenha uma
            experiência mais personalizada.
          </p>
          <p>Veja mais detalhes em nossa Política de privacidade</p>
          <button className={styles.button} onClick={handleAcceptCookies}>
            Aceitar
          </button>
        </div>
      )}

      {sessionId && !yourName && !error && (
        <div className={styles.cookiesMessage}>
          <h4>Qual seu nome?</h4>
          <p>(Usaremos para deixar a conversa mais personalizada)</p>
          <input
            type="text"
            value={inputName ?? ''}
            onChange={e => setInputName(e.target.value)}
            className={styles.input}
          />
          <button className={styles.button} onClick={handleSendMyName}>
            Enviar
          </button>
        </div>
      )}
      {sessionId && yourName && !error && (
        <div className={styles.footer}>
          <input
            type="text"
            className={styles.input}
            value={input}
            onChange={e => setInput(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleSendMessage()}
            disabled={!!options?.length}
            placeholder={
              options?.length
                ? 'Escolha uma opção acima'
                : 'Digite sua mensagem...'
            }
          />
          {!isSendMediaDisabled && (
            <Botao
              variant="outline-primary"
              icon={mdiCamera}
              onClick={() => setSelfie(true)}
            />
          )}
          <Botao
            disabled={isSendMessageDisabled}
            variant="primary"
            onClick={handleSendMessage}
          >
            Enviar
          </Botao>
        </div>
      )}
      {error && (
        <div className={styles.error}>
          <h4>Erro</h4>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default App;
