import { mdiChartLine } from '@mdi/js';
import styles from './NavCategorias.module.css';
import Icon from '@mdi/react';
import NavCatItem from './NavCatItem';

type Props = {
  value: any[];
  selected: string | null;
  onSelect?: (value: any) => void;
  setIsOver: (value: boolean) => void;
};

const NavCategorias = ({ value, selected, onSelect, setIsOver }: Props) => {
  return (
    <ul
      className={styles.container}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
    >
      {value?.map((item, index) => (
        <NavCatItem
          key={item.label ?? index}
          value={item}
          onClick={onSelect}
          selected={selected === item.label}
        />
      ))}
    </ul>
  );
};

export default NavCategorias;
