import { format, parseISO } from 'date-fns';

export const TextoMetadados = ({ type, value }: any) => {
  switch (type) {
    case 'data':
      return format(parseISO(value), 'dd/MM/yyyy');
    case 'booleano':
      return value ? 'Sim' : 'Não';
    case 'lista':
      return value && Array.isArray(value) ? value.join(', ') : value ?? '-';
    default:
      return value;
  }
};
