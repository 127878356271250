import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import axios from 'axios';
import { useQuery } from 'react-query';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import CampoSenha from '../../components/Formulario/CampoSenha';
import { DiaExpediente } from './DiaExpediente';
import Botao from '../../components/Botao';
import {
  mdiEmail,
  mdiInformationSlabCircle,
  mdiPhone,
  mdiSend,
  mdiTagPlus,
  mdiTrashCan,
  mdiWhatsapp,
} from '@mdi/js';
import AlertBox from '../../components/layout/AlertBox';
import Icon from '@mdi/react';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import IconeCanal from '../../components/layout/IconeCanal';
import HBox from '../../components/layout/HBox';
import VBox from '../../components/layout/VBox';
import CampoNumero from '../../components/Formulario/CampoNumero';
import CampoTelefone from '../../components/Formulario/CampoTelefone';
import { Tooltip } from '../../components/layout/Tooltip';
import { parseTelefone, parseTelefoneZap } from '@tera/shared/src/lib';
import Marcadores from '@/components/layout/Marcadores';
import { useState } from 'react';
import { CampoPersonalizacao } from '@/components/Formulario/CampoPersonalizacao';
import { contatoTipoOptions } from './contatoTipoOptions';

export const FormCliente = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const qCustomize = useQuery('cliente/meta', async () => {
    const res = await axios.get('/api/cliente/meta', {
      withCredentials: true,
    });

    return res.data.result;
  });

  const qTags = useQuery('tags', async () => {
    const { data } = await axios.get('/api/marcador/box');
    return data?.result ?? [];
  });

  const qGrupos = useQuery('cliente_grupo/box', async () => {
    const { data } = await axios.get('/api/cliente_grupo/box');
    return data?.result ?? [];
  });
  const gruposOptions = qGrupos.data?.map((it: any) => ({
    id: it.id,
    label: it.nome,
  }));

  const qUsuarios = useQuery('usuario/box', async () => {
    const { data } = await axios.get('/api/usuario/box');
    return data?.result ?? [];
  });
  const usuariosOptions = qUsuarios.data?.map((it: any) => ({
    id: it.id,
    label: it.nome,
    group: it.grupo?.nome,
  }));

  const optionsTags = qTags.data
    ?.filter((tag: any) => ['qualquer', 'cliente'].includes(tag.local))
    ?.map((tag: any) => ({
      id: tag.id,
      label: tag.nome,
    }));

  const estiloFilaOptions = [
    { id: 'unica', label: 'Sequencial' },
    { id: 'paralela', label: 'Paralela' },
  ];

  const addNewContato = (tipo: string, valor: string = '') => {
    onChange({
      ...value,
      contatos: [{ tipo, valor }, ...(value?.contatos ?? [])],
    });
  };
  const [newTag, setNewTag] = useState<string>('');

  const idsMarcadores = value?.marcadores?.map((it: any) => it.id);

  return (
    <Grid>
      <div style={{ position: 'absolute', height: 0 }}>
        <input
          type="text"
          name="username"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
        <input
          type="password"
          name="password"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
      </div>
      <GridItem sm={12} md={8}>
        <CampoTexto
          value={value?.nome ?? ''}
          onChange={v => onChange({ ...value, nome: v })}
          label="Nome"
          error={fieldErrors?.nome}
        />
      </GridItem>

      <GridItem sm={12} md={4}>
        <CampoSeletor
          options={gruposOptions}
          value={value?.grupo_id}
          onChange={v => onChange({ ...value, grupo_id: v })}
          label="Grupo"
          error={fieldErrors?.grupo_id}
        />
      </GridItem>

      <GridItem sm={12} md={4}>
        <CampoTexto
          value={value?.empresa ?? ''}
          onChange={v => onChange({ ...value, empresa: v })}
          label="Empresa"
          error={fieldErrors?.empresa}
        />
      </GridItem>

      <GridItem sm={12} md={4}>
        <CampoSeletor
          noSearch
          value={value?.estado}
          onChange={v => onChange({ ...value, estado: v })}
          label="Situação"
          options={[
            { id: 'ativo', label: 'Ativo' },
            {
              id: 'bloqueado_campanha',
              label: 'Bloqueados para campanhas',
            },
            { id: 'bloqueado', label: 'Bloqueado' },
          ]}
          error={fieldErrors?.estado}
        />
      </GridItem>

      <GridItem sm={12} md={4}>
        <CampoSeletor
          value={value?.usuario_responsavel_id}
          onChange={v => onChange({ ...value, usuario_responsavel_id: v })}
          label="Atendente responsável"
          options={usuariosOptions}
          placeholder="(Não vinculado)"
          error={fieldErrors?.estado}
        />
      </GridItem>

      <GridItem sm={12}>
        <label>Marcadores</label>
        <HBox stretch>
          <CampoSeletor
            options={optionsTags.filter(
              (it: any) => !idsMarcadores?.includes(it.id),
            )}
            value={newTag}
            onChange={v => setNewTag(v)}
            noSearch
          />
          <div style={{ width: '72px', textAlign: 'end' }}>
            <Botao
              icon={mdiTagPlus}
              variant="outline-primary"
              onClick={() => {
                onChange({
                  ...value,
                  marcadores: [
                    ...(value?.marcadores ?? []),
                    qTags.data?.find((it: any) => it.id === +newTag),
                  ],
                });
              }}
            />
          </div>
        </HBox>
        <div style={{ margin: '4px 0 8px 0' }}>
          <Marcadores
            ids={idsMarcadores}
            onRemove={(id: number) =>
              onChange({
                ...value,
                marcadores: value?.marcadores?.filter(
                  (it: any) => it.id !== id,
                ),
              })
            }
          />
        </div>
      </GridItem>
      <GridItem sm={12}>
        <p>Adicione quantos tipos de contato desejar:</p>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <HBox>
            <Botao
              variant="outline-primary"
              icon={mdiWhatsapp}
              onClick={() => addNewContato('whatsapp')}
            >
              WhatsApp
            </Botao>
            <Botao
              variant="outline-primary"
              icon={mdiPhone}
              onClick={() => addNewContato('telefone')}
            >
              Telefone
            </Botao>
            <Botao
              variant="outline-primary"
              icon={mdiSend}
              onClick={() => addNewContato('telegram')}
            >
              Telegram
            </Botao>
            <Botao
              variant="outline-primary"
              icon={mdiEmail}
              onClick={() => addNewContato('email')}
            >
              E-mail
            </Botao>
          </HBox>

          <p style={{ color: 'var(--tc-color-gray-600', fontSize: '14px' }}>
            <Icon path={mdiInformationSlabCircle} size="16px" /> O contato de
            whatsapp tem formato diferente do número de telefone
          </p>

          <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
            {value?.contatos?.map((contato: any, idx: number) => (
              <div
                key={idx}
                style={{
                  borderRadius: '8px',
                  border: '1px solid var(--tc-color-gray-300)',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4px',
                  alignItems: 'center',
                  padding: '1px 8px 1px 1px',
                }}
              >
                <div
                  style={{ flex: 1, background: 'var(--tc-color-gray-100)' }}
                >
                  <CampoSeletor
                    noSearch
                    noBorder
                    value={contato.tipo}
                    onChange={v => {
                      const newContatos = [...value.contatos];
                      newContatos[idx].tipo = v;
                      onChange({ ...value, contatos: newContatos });
                    }}
                    options={contatoTipoOptions}
                  />
                </div>

                <div
                  style={{
                    borderLeft: '1px solid var(--tc-color-gray-300)',
                    flex: 1,
                    margin: 0,
                    padding: '0 8px',
                    border: 'none',
                    outline: 'none',
                    lineHeight: '40px',
                  }}
                >
                  {['whatsapp', 'telegram'].includes(contato.tipo) && (
                    <CampoNumero
                      noBorder
                      value={contato.valor ?? ''}
                      onChange={v => {
                        const newContatos = [...value.contatos];
                        newContatos[idx].valor = v;
                        onChange({ ...value, contatos: newContatos });
                      }}
                      mostrarSeparadorMilhar={false}
                      decimais={0}
                      somentePositivos={true}
                    />
                  )}

                  {contato.tipo === 'telefone' && (
                    <CampoTelefone
                      noBorder
                      value={contato.valor ?? ''}
                      onChange={v => {
                        const newContatos = [...value.contatos];
                        newContatos[idx].valor = v;
                        onChange({ ...value, contatos: newContatos });
                      }}
                    />
                  )}

                  {contato.tipo === 'email' && (
                    <CampoTexto
                      noBorder
                      type="email"
                      value={contato.valor ?? ''}
                      onChange={v => {
                        const newContatos = [...value.contatos];
                        newContatos[idx].valor = v;
                        onChange({ ...value, contatos: newContatos });
                      }}
                    />
                  )}

                  {contato.tipo === 'webchat' && (
                    <CampoTexto
                      noBorder
                      value={contato.valor ?? ''}
                      onChange={v => {
                        const newContatos = [...value.contatos];
                        newContatos[idx].valor = v;
                        onChange({ ...value, contatos: newContatos });
                      }}
                    />
                  )}
                </div>

                {contato.tipo === 'telefone' &&
                  !value.contatos.some((c: any) => c.tipo === 'whatsapp') && (
                    <Tooltip text="Adicionar contato de whatsapp a partir deste telefone">
                      <Botao
                        variant="none-primary"
                        onClick={() =>
                          addNewContato(
                            'whatsapp',
                            parseTelefoneZap(contato.valor),
                          )
                        }
                        icon={mdiWhatsapp}
                      />
                    </Tooltip>
                  )}

                {contato.tipo === 'whatsapp' &&
                  !value.contatos.some((c: any) => c.tipo === 'telefone') && (
                    <Tooltip text="Adicionar contato de telefone a partir deste whatsapp">
                      <Botao
                        variant="none-info"
                        onClick={() =>
                          addNewContato(
                            'telefone',
                            parseTelefone(contato.valor),
                          )
                        }
                        icon={mdiPhone}
                      />
                    </Tooltip>
                  )}

                <BotaoPerigoso
                  icon={mdiTrashCan}
                  variant="none-danger"
                  onClick={() => {
                    const newContatos = [...value.contatos];
                    newContatos.splice(idx, 1);
                    onChange({ ...value, contatos: newContatos });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </GridItem>

      {qCustomize.data?.length > 0 && (
        <GridItem sm={12}>
          <div style={{ marginTop: '12px' }}>Campos personalizados</div>
          <Grid>
            {qCustomize.data?.map((it: any) => (
              <GridItem sm={12} md={6}>
                <CampoPersonalizacao
                  label={it.rotulo}
                  type={it.tipo}
                  value={value?.metadados?.[it.nome] ?? ''}
                  onChange={(v: any) => {
                    onChange({
                      ...value,
                      metadados: {
                        ...value.metadados,
                        [it.nome]: v,
                      },
                    });
                  }}
                />
              </GridItem>
            ))}
          </Grid>
        </GridItem>
      )}
    </Grid>
  );
};
