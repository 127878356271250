import React, { forwardRef } from 'react';
import styles from './VBox.module.css';

const VBox = forwardRef<
  HTMLDivElement,
  Readonly<{
    children: React.ReactNode;
    gap?: number | string;
    style?: React.CSSProperties;
    center?: boolean;
    vcenter?: boolean;
  }>
>(({ children, gap, style, center, vcenter }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        ...(style ?? {}),
        gap,
        alignItems: center ? 'center' : undefined,
        justifyContent: vcenter ? 'center' : undefined,
      }}
      className={styles.vbox}
    >
      {children}
    </div>
  );
});

export default VBox;
