import { parse } from 'date-fns';
import { dateWithoutTimezone } from '../../lib/util';

export type FilterProps = {
  periodo?: [string, string, string];
  canal_id?: number[];
  grupo_id?: number[];
};

export const translateFilter = ({
  periodo,
  canal_id,
  grupo_id,
}: FilterProps): any => {
  const _filter: any = {};
  if (periodo) {
    const d1 = parse(periodo[1], 'yyyy-MM-dd', new Date());
    d1.setHours(0, 0, 0, 0);

    const d2 = parse(periodo[2], 'yyyy-MM-dd', new Date());
    d2.setHours(23, 59, 59, 999);

    _filter.data_criacao = [
      'between',
      [dateWithoutTimezone(d1), dateWithoutTimezone(d2)],
    ];
  }

  if (canal_id?.length) {
    _filter.canal_id = ['in', canal_id];
  }

  if (grupo_id?.length) {
    _filter.grupo_id = ['in', grupo_id];
  }

  return JSON.stringify(_filter);
};
