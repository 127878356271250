import Botao from '@/components/Botao';
import BotaoPerigoso from '@/components/BotaoPerigoso';
import CampoCheckbox from '@/components/Formulario/CampoCheckbox';
import { CampoPersonalizacao } from '@/components/Formulario/CampoPersonalizacao';
import CampoSeletor from '@/components/Formulario/CampoSeletor';
import CampoTexto from '@/components/Formulario/CampoTexto';
import HBox from '@/components/layout/HBox';
import VBox from '@/components/layout/VBox';
import { mdiPlus, mdiTrashCan } from '@mdi/js';
import CampoMultiTexto from '../Formulario/CampoMultiTexto';

export const FormMetadados = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const newCustomizeObj = () => ({
    nome: '',
    tipo: 'texto',
    valor_padrao: '',
    obrigatorio: false,
    filtravel: true,
    listavel: true,
  });

  return (
    <VBox>
      <HBox stretch>
        <p></p>
        <Botao
          variant="outline-primary"
          icon={mdiPlus}
          onClick={() => onChange([...(value ?? []), newCustomizeObj()])}
        >
          Adicionar campo
        </Botao>
      </HBox>

      <table style={{ width: '100%' }}>
        <tr>
          <th>Rótulo</th>
          <th>Tipo</th>
          <th>Valor padrão</th>
          <th>Obrigatório?</th>
          <th>Filtrável?</th>
          <th>Listável?</th>
          <th>Ações</th>
        </tr>
        <tr>
          <td colSpan={7}>
            <hr />
          </td>
        </tr>
        {value?.map((obj: any, idx: number) => (
          <tr>
            <td>
              <CampoTexto
                value={obj.rotulo}
                onChange={v =>
                  onChange(
                    value?.map((it: any, i: number) =>
                      i === idx ? { ...it, rotulo: v } : it,
                    ),
                  )
                }
                error={fieldErrors?.[`${idx}.rotulo`]}
              />
            </td>
            <td>
              <HBox>
                <div style={{ width: '120px' }}>
                  <CampoSeletor
                    noSearch
                    options={[
                      { id: 'texto', label: 'Texto' },
                      { id: 'numero', label: 'Número' },
                      { id: 'data', label: 'Data' },
                      { id: 'booleano', label: 'Booleano' },
                      { id: 'lista', label: 'Lista' },
                      { id: 'opcoes', label: 'Opções' },
                    ]}
                    value={obj.tipo}
                    onChange={v =>
                      onChange(
                        value?.map((it: any, i: number) =>
                          i === idx ? { ...it, tipo: v } : it,
                        ),
                      )
                    }
                    error={fieldErrors?.[`${idx}.tipo`]}
                  />
                </div>
                {obj.tipo === 'opcoes' && (
                  <CampoMultiTexto
                    value={obj.opcoes}
                    onChange={v =>
                      onChange(
                        value?.map((it: any, i: number) =>
                          i === idx ? { ...it, opcoes: v } : it,
                        ),
                      )
                    }
                    placeholder="Opções"
                    error={fieldErrors?.[`${idx}.opcoes`]}
                  />
                )}
              </HBox>
            </td>
            <td>
              <CampoPersonalizacao
                type={obj.tipo}
                onTypeChanged={() =>
                  onChange(
                    value?.map((it: any, i: number) =>
                      i === idx ? { ...it, valor_padrao: null } : it,
                    ),
                  )
                }
                value={obj.valor_padrao}
                onChange={(v: any) =>
                  onChange(
                    value?.map((it: any, i: number) =>
                      i === idx ? { ...it, valor_padrao: v } : it,
                    ),
                  )
                }
                error={fieldErrors?.[`${idx}.valor_padrao`]}
                options={obj.opcoes?.map((it: any) => ({ id: it, label: it }))}
              />
            </td>
            <td>
              <CampoCheckbox
                text="Obrigatório"
                value={obj.obrigatorio}
                onChange={v =>
                  onChange(
                    value?.map((it: any, i: number) =>
                      i === idx ? { ...it, obrigatorio: v } : it,
                    ),
                  )
                }
                error={fieldErrors?.[`${idx}.obrigatorio`]}
              />
            </td>
            <td>
              <CampoCheckbox
                text="Filtrável"
                value={obj.filtravel}
                onChange={v =>
                  onChange(
                    value?.map((it: any, i: number) =>
                      i === idx ? { ...it, filtravel: v } : it,
                    ),
                  )
                }
                error={fieldErrors?.[`${idx}.filtravel`]}
              />
            </td>
            <td>
              <CampoCheckbox
                text="Listável"
                value={obj.listavel}
                onChange={v =>
                  onChange(
                    value?.map((it: any, i: number) =>
                      i === idx ? { ...it, listavel: v } : it,
                    ),
                  )
                }
                error={fieldErrors?.[`${idx}.listavel`]}
              />
            </td>
            <td>
              <HBox style={{ justifyContent: 'flex-end' }}>
                <BotaoPerigoso
                  variant="none-danger"
                  icon={mdiTrashCan}
                  onClick={() =>
                    onChange(value?.filter((it: any, i: number) => i !== idx))
                  }
                />
              </HBox>
            </td>
          </tr>
        ))}
      </table>
    </VBox>
  );
};
