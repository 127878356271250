import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "ticketsList",
  label: "[Tomticket] Listar chamados cliente",
  description: "Lista os chamados de um cliente no TomTicket.",
  category: "Tomticket",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "string", name: "token", label: "Token" },
      { type: "string", name: "email", label: "Email" },
      { type: "string", name: "id_cliente", label: "ID cliente" },
      { type: "string", name: "situacao", label: "Situacao" },
    ];
  },
  outputs() {
    return [
      { type: "list", name: "list", label: "Chamados" },
      { type: "route", name: "route", label: "Saída fluxo" },
    ];
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: "[Tomticket] Listar chamados cliente", indent });

    const next = nextNodeId(nodeId, "route");
    if (next) {
      stack.push({ id: next, indent: indent });
    }
  },
};

export default instance;
