import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "booleanCompare",
  label: "Sim/Não (fluxo)",
  description: "Sim/Não para fluxo de execução",
  category: "Sim/Não",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "boolean", name: "boolean", label: "Sim/Não" },
    ];
  },
  outputs() {
    return [
      { type: "route", name: "route-true", label: "Verdadeiro" },
      { type: "route", name: "route-false", label: "Falso" },
    ];
  },
  async resolveOutputs(inputs) {
    return { route: `route-${Boolean(inputs.boolean).toString()}` };
  },

  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: `[Sim/Não]`, indent });
    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: `(SE verdadeiro)`, indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-false");
    if (nextFalse) {
      stack.push({ result: `(SE falso)`, indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }
    stack.push({ result: `(Fim SE)`, indent });
  },
};

export default instance;
