import React, { FormEvent, useCallback, useEffect } from 'react';
import styles from './CampoMultiSeletor.module.css';
import Icon from '@mdi/react';
import { mdiDeleteCircleOutline, mdiPlus } from '@mdi/js';
import HBox from '../layout/HBox';
import Botao from '../Botao';

const DynamicWidthInput = ({ value, onChange, ...props }: any) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const spanRef = React.useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (spanRef.current) {
      const width = spanRef.current.offsetWidth;
      inputRef.current!.style!.width = `${width + 4}px`; // Ajuste a largura do input
    }
  }, [value]);

  return (
    <>
      <span
        ref={spanRef}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'pre',
          fontSize: '16px',
        }}
      >
        {value ?? ''}
      </span>
      <input
        ref={inputRef}
        {...props}
        value={value ?? ''}
        onChange={e => onChange(e.target.value)}
      />
    </>
  );
};

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
  label?: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  [key: string]: any;
};

export default function CampoMultiTexto({
  value,
  onChange,
  label,
  placeholder,
  type = 'text',
  required,
  disabled,
  error,
  noSearch,
  ...props
}: Readonly<Props>) {
  const [valueShadow, setValueShadow] = React.useState<(string | null)[]>([]);
  const [shadowIndex, setShadowIndex] = React.useState<number>(-1);

  useEffect(() => {
    const s: (string | null)[] = [null];

    if (value && Array.isArray(value)) {
      value?.forEach((v: string) => {
        s.push(v, null);
      });
    }

    setValueShadow(s);
  }, [value]);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const removeSelected = useCallback(
    (index: number) => {
      onChange && onChange(value?.filter((_, i) => i !== index) ?? []);
    },
    [value, onChange],
  );

  const addNewValue = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();

      onChange?.(valueShadow.filter(v => Boolean(v?.length)) as string[]);
    },
    [valueShadow, onChange],
  );

  return (
    <div>
      <label>{label}</label>
      <div
        ref={containerRef}
        className={[
          styles.container,
          error ? styles.error : null,
          disabled ? styles.disabled : null,
        ].join(' ')}
        tabIndex={disabled ? undefined : 0}
      >
        <div className={styles.selectedContainer}>
          {placeholder &&
            valueShadow.length === 1 &&
            !valueShadow[0]?.length && (
              <div className={styles.placeholder}>{placeholder}</div>
            )}
          <form onSubmit={addNewValue} onBlur={addNewValue}>
            <HBox gap="0">
              {value &&
                Array.isArray(value) &&
                value.map((v: string, sidx: number) => (
                  <React.Fragment key={sidx}>
                    <DynamicWidthInput
                      style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        outline: 'none',
                        padding: '0',
                        margin: '0',
                        fontSize: '1rem',
                      }}
                      value={valueShadow?.[sidx * 2] ?? ''}
                      onChange={(v1: string) =>
                        setValueShadow(prev => [
                          ...(prev?.slice(0, sidx * 2) ?? []),
                          v1,
                          ...(prev?.slice(sidx * 2 + 1) ?? []),
                        ])
                      }
                      onFocus={() => setShadowIndex(sidx * 2)}
                      disabled={disabled}
                    />

                    <div key={sidx} className={styles.chip}>
                      <div className={styles.chipLabel}>
                        {v}
                        <button
                          onClick={() => removeSelected(sidx)}
                          type="button"
                          disabled={disabled}
                        >
                          <Icon path={mdiDeleteCircleOutline} size="16px" />
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              <input
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  outline: 'none',
                  padding: '0',
                  margin: '0',
                  fontSize: '1rem',
                }}
                value={valueShadow?.[valueShadow.length - 1] ?? ''}
                onChange={e =>
                  setValueShadow([
                    ...(valueShadow?.slice(0, valueShadow!.length - 1) ?? []),
                    e.target.value,
                  ])
                }
                onFocus={() => setShadowIndex(valueShadow.length - 1)}
                disabled={disabled}
              />
              <button
                type="submit"
                style={{ padding: '0', width: 0, height: 0 }}
                tabIndex={-1}
                disabled={disabled}
              />
            </HBox>
          </form>
        </div>
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
