import React, { ReactNode } from 'react';
import HBox from './HBox';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import styles from './Accordion.module.css';

type AccordionVariants =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';

type AccordionProps = {
  title: ReactNode;
  children: any;
  startOpen?: boolean;
  variant?: AccordionVariants;
};
export const Accordion = ({
  title,
  children,
  startOpen,
  variant = 'primary',
}: AccordionProps) => {
  const [open, setOpen] = React.useState(startOpen || false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.container}>
      <div
        onClick={toggleOpen}
        className={[styles.titleContainer, styles[variant]].join(' ')}
      >
        <HBox>
          <div>{title}</div>
          <Icon path={open ? mdiChevronDown : mdiChevronUp} size={1} />
          <div className={[styles.bar, styles[variant]].join(' ')} />
        </HBox>
      </div>
      <div
        className={styles.content}
        style={{
          height: open ? 'auto' : '0',
          opacity: open ? 1 : 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};
