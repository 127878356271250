import React, { useCallback, useEffect } from 'react';
import styles from './CampoSeletor.module.css';
import Icon from '@mdi/react';
import {
  mdiChevronDown,
  mdiChevronUp,
  mdiInformationSlabCircleOutline,
  mdiMagnify,
} from '@mdi/js';
import ReactDOM from 'react-dom';
import { throttle } from '../../lib/util';
import CampoTexto from './CampoTexto';
import ReactDOMServer from 'react-dom/server';
import HBox from '../layout/HBox';
import { Tooltip } from '../layout/Tooltip';

export type CampoSeletorOption = {
  id: string;
  label: React.ReactNode;
  group?: string;
  error?: boolean;
};

type CampoSeletorOptionsProps = {
  selectedIndex: number;
  onChangeSelectedIndex: (index: number) => void;
  value: CampoSeletorOption[];
  onSelect: (value: string) => void;

  noSearch?: boolean;
  search: string;
  onChangeSearch: (value: string) => void;
};

export const CampoSeletorOptions = ({
  selectedIndex,
  onChangeSelectedIndex,
  value,
  onSelect,
  noSearch,
  search,
  onChangeSearch,
}: CampoSeletorOptionsProps) => {
  const filteredOptions =
    search.length > 0
      ? value?.filter(
          it =>
            !it.label ||
            ReactDOMServer.renderToString(it.label)
              .toLowerCase()
              .includes(search.toLowerCase()),
        )
      : value;

  const groups = filteredOptions?.reduce<{
    [key: string]: CampoSeletorOption[];
  }>((acc, it) => {
    if (it.group) {
      acc[it.group] = acc[it.group] ?? [];
      acc[it.group].push(it);
    } else {
      acc[''] = acc[''] ?? [];
      acc[''].push(it);
    }
    return acc;
  }, {});

  return (
    <div>
      {!noSearch && (
        <CampoTexto
          prepend={
            <Icon path={mdiMagnify} size={1} color="var(--tc-color-gray-400)" />
          }
          placeholder="Pesquisar"
          onClick={(e: any) => {
            console.log('input click');
          }}
          value={search}
          onChange={onChangeSearch}
        />
      )}
      {search.length && !filteredOptions?.length ? (
        <div className={styles.noResults}>(Nenhum resultado encontrado)</div>
      ) : null}
      {groups
        ? Object.entries(groups).map(([group, gOptions], gidx) => {
            return (
              <div key={gidx}>
                {group && <div className={styles.group}>{group}</div>}
                {gOptions.map((option, index) => (
                  <div
                    tabIndex={0}
                    key={option.id}
                    className={[
                      styles.option,
                      selectedIndex === index ? styles.selected : null,
                      group.length > 0 ? styles.grouped : null,
                      option.error ? styles.error : null,
                    ].join(' ')}
                    onClick={() => {
                      onChangeSelectedIndex(index);
                      onSelect?.(option.id);
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            );
          })
        : null}
    </div>
  );
};

type CampoSeletorProps = {
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
  options: CampoSeletorOption[];
  disabled?: boolean;
  error?: string;
  noSearch?: boolean;
  noBorder?: boolean;
  tooltipText?: React.ReactNode;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';

  [key: string]: any;
};

export default function CampoSeletor({
  value,
  onChange,
  label,
  placeholder,
  type = 'text',
  required,
  options,
  disabled,
  error,
  noSearch,
  tooltipText,
  tooltipPosition,
  noBorder,
  ...props
}: Readonly<CampoSeletorProps>) {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState<string>('');

  const [dropdownPosition, setDropdownPosition] = React.useState<'down' | 'up'>(
    'down',
  );

  const filteredOptions =
    search.length > 0
      ? options?.filter(
          it =>
            !it.label ||
            ReactDOMServer.renderToString(it.label)
              .toLowerCase()
              .includes(search.toLowerCase()),
        )
      : options;

  const selected = filteredOptions?.[selectedIndex];
  const containerRef = React.useRef<HTMLDivElement>(null);
  const optionsContainerRef = React.useRef<HTMLDivElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (disabled) return;

    let hit = true;
    switch (e.code.toLowerCase()) {
      case 'space':
      case 'enter':
        setOpen(!open);
        break;
      case 'arrowdown':
        if (filteredOptions?.length) {
          setSelectedIndex((selectedIndex + 1) % filteredOptions?.length);
          setOpen(true);
        }
        break;
      case 'arrowup':
        if (filteredOptions?.length) {
          setSelectedIndex(
            (selectedIndex - 1 + filteredOptions?.length) %
              filteredOptions?.length,
          );
          setOpen(true);
        }
        break;
      default:
        hit = false;
    }
    if (hit) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleContainerClick = (e: any) => {
    if (disabled || e.target.closest(`.${styles.options}`)) {
      e.stopPropagation();
      return;
    }

    setOpen(!open);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        e.target instanceof HTMLElement &&
        e.target.closest(`.${styles.container}`)
      ) {
        e.stopPropagation();
        return;
      }
      setOpen(false);
    };

    const handleBlur = (e: FocusEvent) => {
      if (
        e.relatedTarget instanceof HTMLElement &&
        (e.relatedTarget.closest(`.${styles.container}`) ||
          e.relatedTarget.closest(`.${styles.options}`))
      ) {
        return;
      }
      setOpen(false);
    };

    const cr = containerRef.current;
    document.addEventListener('click', handleClick);
    cr?.addEventListener('blur', handleBlur);

    return () => {
      document.removeEventListener('click', handleClick);
      cr?.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    if (value) {
      setSelectedIndex(
        filteredOptions?.findIndex(
          it => it.id?.toString() === value?.toString(),
        ) ?? -1,
      );
    } else {
      setSelectedIndex(-1);
    }
  }, [value, filteredOptions]);

  useEffect(() => {
    if (!open) return;

    const containerRect = containerRef.current?.getBoundingClientRect();
    const optionsRect = optionsContainerRef.current?.getBoundingClientRect();

    if (containerRect && optionsRect) {
      const overflow =
        containerRect.bottom + optionsRect.height - window.innerHeight;
      setDropdownPosition(overflow > 0 ? 'up' : 'down');
    }
  }, [open]);

  const [offset, setOffset] = React.useState({
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
  });
  useEffect(() => {
    const adjust = () => {
      if (open) {
        const rect = containerRef.current!.getBoundingClientRect();
        const optRect = optionsContainerRef.current!.getBoundingClientRect();

        console.log(rect);
        console.log(
          dropdownPosition,
          window.innerHeight,
          optRect.height,
          rect.bottom + rect.height + window.scrollY,
        );

        setOffset({
          top: rect.bottom + window.scrollY,
          bottom: Math.min(
            window.innerHeight - optRect.height + window.scrollY,
            window.innerHeight - rect.bottom + rect.height + window.scrollY,
          ),
          left: rect.left + window.scrollX + 12,
          width: rect.width + 'px',
        });
      }
    };
    window.addEventListener('scroll', adjust);
    window.addEventListener('resize', adjust);
    adjust();

    return () => {
      window.removeEventListener('scroll', adjust);
      window.removeEventListener('resize', adjust);
    };
  }, [open]);

  return (
    <div style={{ width: `100%` }}>
      <label>
        <HBox stretch>
          <span>{label}</span>
          {tooltipText && (
            <Tooltip text={tooltipText} position={tooltipPosition}>
              <Icon
                path={mdiInformationSlabCircleOutline}
                size="14px"
                color="var(--tc-color-info)"
              />
            </Tooltip>
          )}
        </HBox>
      </label>
      <div
        ref={containerRef}
        className={[
          styles.container,
          error ? styles.error : null,
          disabled ? styles.disabled : null,
        ].join(' ')}
        tabIndex={disabled ? undefined : 0}
        onKeyDown={handleKeyDown}
        onClick={handleContainerClick}
        style={{ border: noBorder ? 'none' : undefined }}
      >
        <div className={styles.selectedContainer}>
          {placeholder && !selected && (
            <div className={styles.placeholder}>{placeholder}</div>
          )}
          {selected && <div>{selected?.label}</div>}
        </div>
        {open &&
          ReactDOM.createPortal(
            <div
              ref={optionsContainerRef}
              className={[
                styles.options,
                dropdownPosition == 'down'
                  ? styles.optionsDown
                  : styles.optionsUp,
              ].join(' ')}
              style={{
                top: dropdownPosition == 'down' ? offset.top : undefined,
                bottom: dropdownPosition == 'down' ? undefined : offset.bottom,
                left: offset.left,
                width: offset.width,
              }}
            >
              <CampoSeletorOptions
                value={options}
                onSelect={v => {
                  onChange?.(v);
                  setOpen(false);
                }}
                noSearch={noSearch}
                search={search}
                onChangeSearch={setSearch}
                selectedIndex={selectedIndex}
                onChangeSelectedIndex={setSelectedIndex}
              />
            </div>,
            document.querySelector('#root')!,
          )}

        <div className={styles.arrow}>
          <Icon path={open ? mdiChevronUp : mdiChevronDown} size={1} />
        </div>
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
