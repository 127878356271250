import { MarcadorDto } from '@tera/shared/src/dto/MarcadorDto';
import MarcadorSm from './MarcadorSm';
import { useQuery } from 'react-query';
import axios from 'axios';

type Props = {
  ids: number[];
};

const MarcadoresSm = ({ ids }: Props) => {
  const qTags = useQuery(['tags'], async () => {
    const result = await axios.get('/api/marcador/box');
    return result.data?.result;
  });

  return ids?.map((mid, idx) => {
    const tag = qTags.data?.find((t: MarcadorDto) => +t.id == +mid);

    if (!tag) return null;

    return <MarcadorSm color={tag.cor} key={idx} title={tag.nome} />;
  });
};

export default MarcadoresSm;
