import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "groupWorkHours",
  label: "Expediente (Departamento)",
  description:
    "Verifica se o horário atual está dentro ou fora do expediente do departamento",
  category: "Domínio",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "number", name: "group", label: "Departamento" },
    ];
  },
  outputs() {
    return [
      { type: "route", name: "route-true", label: "Dentro do exp." },
      { type: "route", name: "route-false", label: "Fora do exp." },
    ];
  },
  async resolveOutputs(inputs) {
    return {
      number: inputs.domainGroup?.id,
    };
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: `[Expediente]`, indent });
    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: `(SE Dentro do expediente)`, indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-false");
    if (nextFalse) {
      stack.push({ result: `(SE Fora do expediente)`, indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }
    stack.push({ result: `(Fim SE)`, indent });
  },
};

export default instance;
