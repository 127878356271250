import Grid, { GridItem } from '@/components/layout/Grid';
import VBox from '@/components/layout/VBox';
import { ChatMensagemDto } from '@tera/shared/src/dto/ChatMensagemDto';
import HBox from '@/components/layout/HBox';
import Card from '@/components/layout/Card';
import Botao from '@/components/Botao';
import {
  mdiAccountCreditCard,
  mdiContentSave,
  mdiEye,
  mdiOfficeBuilding,
  mdiPen,
  mdiPlus,
  mdiTrashCan,
} from '@mdi/js';
import Icon from '@mdi/react';
import IconeCanal from '@/components/layout/IconeCanal';
import Marcadores from '@/components/layout/Marcadores';
import BotaoPerigoso from '@/components/BotaoPerigoso';
import CampoMemo from '@/components/Formulario/CampoMemo';
import Subtitulo from '@/components/tipografia/Subtitulo';
import Mensagem from '../chat/Mensagem';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import IconeTipoContato from '@/components/layout/IconeTipoContato';
import { Tooltip } from '@/components/layout/Tooltip';
import { toast } from 'react-toastify';
import React from 'react';

const dt = new Date().toISOString();
const ultimasMensagens = [
  {
    id: 1,
    data: dt,
    data_criacao: dt,
    nome: 'Fulano',
    autor: 'Cliente',
    tipo_mime: 'text/message',
    conteudo_ou_url: 'Olá, tudo bem?',
    origem: 'usuario',
  } as ChatMensagemDto,
  {
    id: 2,
    data: dt,
    data_criacao: dt,
    nome: 'Bot',
    tipo_mime: 'text/message',
    conteudo_ou_url: 'Estou ótimo, e você?',
    origem: 'bot',
  } as ChatMensagemDto,
  {
    id: 3,
    data: dt,
    data_criacao: dt,
    nome: 'Atendente',
    tipo_mime: 'text/message',
    conteudo_ou_url: 'Sou o atendente, como posso ajudar?',
    origem: 'atendente',
  } as ChatMensagemDto,
  {
    id: 4,
    data: dt,
    data_criacao: dt,
    nome: 'Fulano',
    tipo_mime: 'text/message',
    conteudo_ou_url: 'Olá, tudo bem?',
    origem: 'usuario',
  } as ChatMensagemDto,
  {
    id: 5,
    data: dt,
    data_criacao: dt,
    nome: 'Bot',
    tipo_mime: 'text/message',
    conteudo_ou_url: 'Estou ótimo, e você?',
    origem: 'bot',
  } as ChatMensagemDto,
  {
    id: 6,
    data: dt,
    data_criacao: dt,
    nome: 'Atendente',
    tipo_mime: 'text/message',
    conteudo_ou_url: 'Sou o atendente, como posso ajudar?',
    origem: 'atendente',
  } as ChatMensagemDto,
];

type Props = {
  value: any;
  onEdit: (id: number) => void;
  onViewChatMessages: (id: number) => void;
};

const ViewCliente = ({ value, onEdit, onViewChatMessages }: Props) => {
  const qUsuarios = useQuery('usuario/box', async () => {
    const response = await axios.get('/api/usuario/box');
    return response.data.result;
  });

  const vendedorResponsavel = qUsuarios.data?.find(
    (u: any) => u.id === value?.usuario_responsavel_id,
  );

  const [obs, setObs] = React.useState(value?.obs);
  React.useEffect(() => {
    setObs(value?.obs);
  }, [value?.obs]);

  const obsMutator = useMutation({
    mutationFn: async (data: any) => {
      await axios.put('/api/cliente/' + data.id, { obs: data.obs });
    },
    onSuccess: () => {
      toast('Observação salva com sucesso', {
        toastId: 'success',
        position: 'top-center',
        type: 'success',
        autoClose: 5000,
      });
    },
    onError: () => {
      toast('Erro ao salvar observação', {
        toastId: 'error',
        position: 'top-center',
        type: 'error',
        autoClose: 5000,
      });
    },
  });

  const qMsgRecentes = useQuery('chat_mensagem/recentes', async () => {
    const params = new URLSearchParams({
      t: '10',
      so: 'desc',
      fi: JSON.stringify({
        'cliente.id': ['eq', value.id],
      }),
    });
    const response = await axios.get('/api/chat_mensagem?' + params);
    return response.data.result;
  });

  return (
    <Grid style={{ gap: '24px' }}>
      <GridItem sm={12} md={4}>
        <VBox>
          <div>
            <Card>
              <VBox>
                <HBox stretch>
                  <span>{value?.nome}</span>
                  <Botao
                    variant="none-primary"
                    icon={mdiPen}
                    onClick={() => onEdit(value.id)}
                  ></Botao>
                </HBox>
                {vendedorResponsavel && (
                  <HBox style={{ color: 'var(--tc-color-gray-500)' }}>
                    <Icon path={mdiAccountCreditCard} size={1} />
                    <span
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      title={vendedorResponsavel.nome}
                    >
                      {vendedorResponsavel.nome}
                    </span>
                  </HBox>
                )}
                {value?.empresa && (
                  <HBox style={{ color: 'var(--tc-color-gray-500)' }}>
                    <Icon path={mdiOfficeBuilding} size={1} />
                    <span
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      title={value.empresa}
                    >
                      {value.empresa}
                    </span>
                  </HBox>
                )}

                {value?.contatos?.map((contato: any, index: number) => (
                  <HBox
                    style={{ color: 'var(--tc-color-gray-500)' }}
                    key={index}
                  >
                    <IconeTipoContato
                      tipo={contato.tipo}
                      style={{ height: '1rem' }}
                    />
                    <span
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      title={contato.valor}
                    >
                      {contato.valor}
                    </span>
                  </HBox>
                ))}
              </VBox>
            </Card>
          </div>

          <Marcadores ids={[1, 2, 3]} />
          <div>
            <Card>
              <HBox stretch>
                <p style={{ textAlign: 'center', fontWeight: 600 }}>Arquivos</p>
                <Botao variant="none-primary" icon={mdiPlus}></Botao>
              </HBox>

              <HBox stretch>
                <span>Detalhes.pdf</span>
                <BotaoPerigoso
                  variant="none-danger"
                  icon={mdiTrashCan}
                ></BotaoPerigoso>
              </HBox>
            </Card>
          </div>
        </VBox>
      </GridItem>
      <GridItem sm={12} md={8}>
        <VBox>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
              marginBottom: '8px',
            }}
          >
            <CampoMemo
              label="Notas / Observações"
              value={obs}
              onChange={setObs}
            />
            <Botao
              variant="primary"
              icon={mdiContentSave}
              onClick={() => obsMutator.mutate({ id: value.id, obs })}
              disabled={obsMutator.isLoading}
            >
              Salvar
            </Botao>
          </div>

          <Subtitulo style={{ textAlign: 'center', fontWeight: 400 }}>
            Histórico
          </Subtitulo>
          {value?.data_criacao && (
            <Card>
              Contato criado em {new Date(value.data_criacao).toLocaleString()}
            </Card>
          )}

          <Card>
            <HBox stretch>
              <IconeCanal tipo="whatsapp" style={{ height: '1.5rem' }} />
              <Botao
                variant="none-primary"
                icon={mdiEye}
                onClick={() => onViewChatMessages(value.id)}
              >
                Ver mensagens
              </Botao>
            </HBox>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                width: '100%',
              }}
            >
              {qMsgRecentes.data?.map((item: any, index: number) => (
                <Mensagem
                  item={item}
                  index={index}
                  mensagens={qMsgRecentes.data}
                  key={item.id}
                  onSelectedKbSection={() => {}}
                />
              ))}
            </div>
          </Card>
        </VBox>
      </GridItem>
    </Grid>
  );
};

export default ViewCliente;
