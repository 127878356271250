import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "boolean",
  label: "Sim/Não",
  description: "Sim/Não",
  category: "Sim/Não",
  inputs() {
    return [{ type: "boolean", name: "boolean", label: "Sim/Não" }];
  },
  outputs() {
    return [{ type: "boolean", name: "boolean", label: "Sim/Não" }];
  },
  async resolveOutputs(inputs) {
    return { boolean: inputs.boolean };
  },

  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: `[Sim/Não]`, indent });
  },
};

export default instance;
