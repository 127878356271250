import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "userById",
  label: "Buscar Usuário",
  description: "Busca um usuário da base pelo seu ID",
  category: "Domínio",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "number", name: "id", label: "ID Usuário" },
    ];
  },
  outputs() {
    return [
      { type: "string", name: "name", label: "Nome" },
      { type: "number", name: "grupo_id", label: "ID Grupo" },
      { type: "string", name: "grupo_nome", label: "Nome grupo" },
      { type: "route", name: "route-true", label: "Usuário existe" },
      { type: "route", name: "route-false", label: "Usuário não existe" },
    ];
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: `[Buscar Usuário]`, indent });

    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: `(SE Usuário existe)`, indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-false");
    if (nextFalse) {
      stack.push({ result: `(SE Usuário não existe)`, indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }

    stack.push({ result: `(Fim SE)`, indent });
  },
};

export default instance;
