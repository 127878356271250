import portProps from '@tera/shared/src/fluxoConfig/ports/object';

type Obj = { id: string; nome: string };
type Props = {
  value: Obj | null;
  onChange: (value: Obj | null) => void;
};

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <div />;
  },
};

export default port;
