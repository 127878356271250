import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import {
  mdiBell,
  mdiLoading,
  mdiLock,
  mdiMagnify,
  mdiPen,
  mdiPlus,
  mdiTarget,
  mdiTrashCan,
} from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation } from 'react-query';
import axios from 'axios';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import { useDebounce } from '../chat/useDebounce';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import { FormMensagemProgramada } from './FormMensagemProgramada';
import Badge from '../../components/layout/Badge';
import { FiltroCanais } from '../historico_mensagens/FiltroCanais';
import Grid, { GridItem } from '../../components/layout/Grid';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

const categoryIcons: Record<string, React.ReactElement> = {
  marketing: (
    <Badge variant="warning">
      <Icon path={mdiTarget} size={0.8} /> Marketing
    </Badge>
  ),
  notificacao: (
    <Badge variant="success">
      <Icon path={mdiBell} size={0.8} /> Notificação
    </Badge>
  ),
  autenticacao: (
    <Badge variant="info">
      <Icon path={mdiLock} size={0.8} /> Autenticação
    </Badge>
  ),
};

type Props = {};

const Page = ({}: Props) => {
  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const [filterCanais, setFilterCanais] = React.useState<string[]>([]);

  const headers: DataTableHeader[] = [
    { label: 'Nome', width: '70%', column: 'nome' },
    {
      label: 'Categoria',
      width: '100px',
      render: row => categoryIcons[row.categoria],
    },
    {
      label: 'Canal associado',
      width: '20%',
      render: row => <span>{row.canal?.nome ?? row.canal_nome}</span>,
    },
    {
      label: 'Situação',
      width: '100px',
      render: (row: any) => {
        switch (row.estado) {
          case 'nao_enviada':
            return <Badge variant="secondary">Não enviada</Badge>;
          case 'avaliacao':
            return <Badge variant="secondary">Avaliação</Badge>;
          case 'aprovada':
            return <Badge variant="success">Aprovada</Badge>;
          case 'rejeitada':
            return <Badge variant="warning">Rejeitada</Badge>;
          default:
            return '(Desconhecida)';
        }
      },
    },
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            <Botao
              variant="none-primary"
              icon={mdiPen}
              onClick={() => setEditObject(row)}
            />
            <BotaoPerigoso
              variant="none-danger"
              icon={mdiTrashCan}
              onClick={() => handleDelete(row.id)}
            />
          </HBox>
        );
      },
    },
  ];

  const queryList = useInfiniteQuery({
    queryKey: ['mensagem_programada'],
    queryFn: async ({ pageParam }) => {
      const where: Record<string, any> = {};

      if (filterCanais.length > 0) {
        where['canal.id'] = ['in', filterCanais];
      }

      const params = new URLSearchParams({
        txt: search,
        c: pageParam,
        fi: JSON.stringify(where),
      });

      return await axios.get<ResponseList>(
        `/api/mensagem_programada?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const changeMutator = useMutation(
    async () => {
      if (!editObject) return;

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(
          `/api/mensagem_programada/${editObject.id}`,
          editObject,
          {
            withCredentials: true,
          },
        );
      } else {
        return await axios.post(`/api/mensagem_programada`, editObject, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
        toast('Modelo salvo com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/mensagem_programada/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Modelo apagado com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        if (err.response.data.status === 422) {
          toast(
            err.response.data.msg ??
              'Ocorreu um erro ao tentar apagar o modelo.',
            {
              toastId: 'error',
              position: 'top-center',
              type: 'warning',
              autoClose: 5000,
            },
          );
        } else {
          toast('Ocorreu um erro ao tentar apagar o modelo.', {
            toastId: 'error',
            position: 'top-center',
            type: 'warning',
            autoClose: 5000,
          });
        }
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate();
  }, [changeMutator]);

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({});
  };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[queryList.data.pages.length - 1]?.data?.total ?? 0;
  const editMode = !!editObject?.id && editObject.id > 0;

  return (
    <LayoutUsuario>
      <Modal
        ignoreOverlayClick
        title={editMode ? 'Editar modelo' : 'Novo modelo'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormMensagemProgramada
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Modelos de mensagem</Titulo>
            <Descricao>
              Gerencie os modelos de mensagens, que podem ser utilizados para
              enviar notificações ou em campanhas.
            </Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Novo
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <HBox>
          <CampoTexto
            prepend={
              <Icon
                path={mdiMagnify}
                size={1}
                color="var(--tc-color-gray-500)"
              />
            }
            placeholder="Pesquisar"
            value={search}
            onChange={setSearch}
          />
        </HBox>

        <Grid>
          <GridItem sm={12}>
            <FiltroCanais value={filterCanais} onChange={setFilterCanais} />
          </GridItem>
        </Grid>

        <div>
          <Botao variant="primary" onClick={() => queryList.refetch()}>
            Aplicar
          </Botao>
        </div>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
