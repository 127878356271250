import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "clientExists",
  label: "[Tomticket] Cliente existe?",
  description:
    "Verifica se um cliente existe no TomTicket, ou pelo email ou pelo identificador interno.",
  category: "Tomticket",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "string", name: "token", label: "Token" },
      { type: "string", name: "email", label: "Email" },
      { type: "string", name: "id_cliente", label: "ID cliente" },
    ];
  },
  outputs() {
    return [
      { type: "route", name: "route-true", label: "Existe" },
      { type: "route", name: "route-false", label: "Não existe" },
    ];
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: `[Tomticket] Cliente existe?`, indent });

    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: `(SE existe)`, indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-false");
    if (nextFalse) {
      stack.push({ result: `(SE não existe)`, indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }
    stack.push({ result: `(Fim SE)`, indent });
  },
};

export default instance;
