import { useEffect, useState } from 'react';
import styles from './onboard.module.css';
import { AnimatedBackground } from './login/AnimatedBackground';
import useWindowSize from '../lib/useWindowSize';
import Botao from '../components/Botao';
import { mdiPauseCircle, mdiPlayCircle } from '@mdi/js';
import AlertBox from '../components/layout/AlertBox';
import axios from 'axios';
import Badge from '@/components/layout/Badge';
import { useMutation, useQuery } from 'react-query';
import CampoCheckbox from '@/components/Formulario/CampoCheckbox';
import HBox from '@/components/layout/HBox';
import VBox from '@/components/layout/VBox';

type Result = {
  ok: boolean;
  result: {
    client: string;
    add: {
      channel: string;
      clientId: string;
      label: string;
    }[];
    remove: {
      channel: string;
      clientId: string;
      label: string;
    }[];
  };
};

const Page = () => {
  const [success, setSuccess] = useState<'true' | 'partial' | 'false' | null>(
    null,
  );

  const [width, height, mobile] = useWindowSize();
  const [pause, setPause] = useState(false);

  const [debug, setDebug] = useState<any>(null);

  const [cliente, setCliente] = useState<string>('');
  const [canaisAutorizados, setCanaisAutorizados] = useState<string[]>([]);
  const [canaisRevogados, setCanaisRevogados] = useState<string[]>([]);

  const [aok, setAok] = useState<boolean | null>(null);

  const confirmData = useQuery(
    'confirmData',
    async () => {
      const response = await axios.get<Result>(
        '/api/onboard' + window.location.search,
      );
      return response.data.result;
    },
    {
      enabled: aok === false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: data => {
        setCliente(data.client);
        setCanaisAutorizados(data.add.map(item => item.channel));
        setCanaisRevogados(data.remove.map(item => item.channel));
      },
    },
  );

  const doOnboard = useMutation(
    async () => {
      const body: Record<string, string> = {
        client: cliente,
        channels: `[${canaisAutorizados.join(',')}]`,
        revoked: `[${canaisRevogados.join(',')}]`,
      };

      const response = await axios.post('/api/onboard', body);
      return response.data.result;
    },
    {
      onSuccess: data => {
        const _add = data.add.filter((item: any) => item.status !== 'success');
        const _remove = data.remove.filter(
          (item: any) => item.status !== 'success',
        );

        if (_add.length === 0 && _remove.length === 0) {
          setSuccess('true');
          setTimeout(() => {
            window.close();
          }, 5000);
        } else {
          setDebug({
            add: _add,
            remove: _remove,
          });
          setSuccess('partial');
        }
      },
      onError: () => {
        setSuccess('false');
      },
    },
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('channels') == '[]' && params.get('revoked') == '[]') {
      setAok(true);
      setTimeout(() => {
        window.close();
      }, 5000);
    } else {
      setAok(false);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div style={{ textAlign: 'center' }}>
          <img src="/logo.png" alt="Logo" style={{ height: '96px' }} />
        </div>

        <h2>Onboard!</h2>

        {!confirmData.isLoading && doOnboard.isIdle && aok === false && (
          <VBox>
            <p>
              Selecione os números que você quer sincronizar com o Terachat e
              clique em Confirmar.
            </p>
            <HBox style={{ justifyContent: 'center' }}>
              <table style={{ width: '80%' }}>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th style={{ width: '25%' }}>Ação</th>
                    <th>Nome</th>
                    <th>Número</th>
                  </tr>
                </thead>
                {confirmData.data?.add?.map((item: any, i: number) => (
                  <tr>
                    <td>
                      <CampoCheckbox
                        text=""
                        disabled={doOnboard.isLoading}
                        value={canaisAutorizados.includes(item.channel)}
                        onChange={checked => {
                          if (checked) {
                            setCanaisAutorizados([
                              ...canaisAutorizados,
                              item.channel,
                            ]);
                          } else {
                            setCanaisAutorizados(
                              canaisAutorizados.filter(c => c !== item.channel),
                            );
                          }
                        }}
                      />
                    </td>
                    <td>
                      {canaisAutorizados.includes(item.channel) ? (
                        <Badge variant="success">Sincronizar</Badge>
                      ) : (
                        <Badge variant="secondary">Ignorar</Badge>
                      )}
                    </td>
                    <td>{item.label}</td>
                    <td>{item.phoneNumber}</td>
                  </tr>
                ))}
                {confirmData.data?.remove?.map((item: any, i: number) => (
                  <tr>
                    <td style={{ width: '10%' }}>
                      <CampoCheckbox
                        disabled={doOnboard.isLoading}
                        text=""
                        value={canaisRevogados.includes(item.channel)}
                        onChange={checked => {
                          if (checked) {
                            setCanaisRevogados([
                              ...canaisRevogados,
                              item.channel,
                            ]);
                          } else {
                            setCanaisRevogados(
                              canaisRevogados.filter(c => c !== item.channel),
                            );
                          }
                        }}
                      />
                    </td>
                    <td>
                      {canaisRevogados.includes(item.channel) ? (
                        <Badge variant="danger">Remover</Badge>
                      ) : (
                        <Badge variant="secondary">Ignorar</Badge>
                      )}
                    </td>
                    <td>{item.label}</td>
                    <td>{item.phoneNumber}</td>
                  </tr>
                ))}
              </table>
            </HBox>
            <Botao
              disabled={doOnboard.isLoading}
              onClick={doOnboard.mutateAsync}
            >
              Confirmar
            </Botao>
          </VBox>
        )}

        {confirmData.isLoading && (
          <AlertBox variant="info">Buscando informações...</AlertBox>
        )}

        {doOnboard.isLoading && (
          <AlertBox variant="info">Criando contas. Aguarde...</AlertBox>
        )}

        {doOnboard.isError && (
          <AlertBox variant="danger">
            <p>
              Ocorreu um erro ao tentar criar as contas. Por favor, entre em
              contato com nosso suporte.
            </p>
            <p>(Você já pode fechar esta janela.)</p>
          </AlertBox>
        )}

        {success === 'false' && (
          <AlertBox variant="danger">
            <p>
              Ocorreu um erro ao tentar criar as contas. Tente novamente mais
              tarde.
            </p>
            <p>Se o erro persistir, entre em contato com nosso suporte.</p>
            <p>(Você já pode fechar esta janela.)</p>
          </AlertBox>
        )}
        {success === 'partial' && (
          <AlertBox variant="warning">
            <p>
              Algumas contas foram sincronizadas com sucesso. Verifique os
              canais habilitados, e tente novamente para habilitar os demais.
            </p>
            <p>Se o erro persistir, entre em contato com nosso suporte.</p>
            <p>(Você já pode fechar esta janela.)</p>
          </AlertBox>
        )}

        {(success === 'true' || aok) && (
          <AlertBox variant="success">
            <p>Contas sincronizadas com sucesso!</p>
            <p>(Fechando em 5 segundos...)</p>
          </AlertBox>
        )}

        {debug && (
          <AlertBox variant="info">
            <p>Segue abaixo o resultado:</p>
            {debug?.add?.map((item: any, i: number) => (
              <p key={i} className={styles[`status-${item.status}`]}>
                Configurar: {item.phoneNumber} - {item.msg}
              </p>
            ))}
            {debug?.remove?.map((item: any, i: number) => (
              <p key={i} className={styles[`status-${item.status}`]}>
                Remover: {item.phoneNumber} - {item.msg}
              </p>
            ))}
          </AlertBox>
        )}
      </div>

      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        {!pause && (
          <Botao
            variant="transparent"
            onClick={() => {
              setPause(true);
            }}
            icon={mdiPauseCircle}
            tabIndex={0}
          >
            Pausar animação
          </Botao>
        )}
        {pause && (
          <Botao
            variant="transparent"
            onClick={() => {
              setPause(false);
            }}
            icon={mdiPlayCircle}
            tabIndex={0}
          >
            Continuar animação
          </Botao>
        )}
      </div>
      <AnimatedBackground width={width} height={height} pause={pause} />
    </div>
  );
};

export default Page;
