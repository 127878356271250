import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "activityTransferToGroup",
  label: "Redirecionar para departamento",
  description: "Redireciona o atendimento para um departamento",
  category: "Chat",
  inputs(inputValues) {
    const result = [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "string", name: "mensagem", label: "Mensagem" },
      { type: "number", name: "id_grupo", label: "Departamento" },
    ];

    if (inputValues?.mensagem) {
      const template = inputValues.mensagem;
      const re = /\{\{([^\}]+?)\}\}/g;

      const ids: string[] = [];
      let res: RegExpExecArray | null;
      while ((res = re.exec(template)) !== null) {
        if (!ids.includes(res[1])) ids.push(res[1]);
      }
      if (ids.length)
        result.push(
          ...ids.map((id) => ({ type: "string", name: id, label: id }))
        );
    }

    return result;
  },
  outputs(/* inputValues, context */) {
    return [
      { type: "route", name: "route-inatividade", label: "Inatividade" },
      { type: "route", name: "route-expirado", label: "T. atend. expirado" },
      { type: "route", name: "route-expediente", label: "Fora do expediente" },
      {
        type: "route",
        name: "route-finalizado",
        label: "Atendimento finalizado",
      },
    ];
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({
      result: `[Redirecionar para departamento] "${inputValues.mensagem}"`,
      indent,
    });

    const nextInatividade = nextNodeId(nodeId, "route-inatividade");
    if (nextInatividade) {
      stack.push({ result: "(SE Inatividade)", indent: indent });
      stack.push({ id: nextInatividade, indent: indent + 1 });
    }

    const nextExpirado = nextNodeId(nodeId, "route-expirado");
    if (nextExpirado) {
      stack.push({
        result: "(SE Tempo de atendimento expirado)",
        indent: indent,
      });
      stack.push({ id: nextExpirado, indent: indent + 1 });
    }

    const nextExpediente = nextNodeId(nodeId, "route-expediente");
    if (nextExpediente) {
      stack.push({ result: "(SE Fora do expediente)", indent: indent });
      stack.push({ id: nextExpediente, indent: indent + 1 });
    }

    const nextFinalizado = nextNodeId(nodeId, "route-finalizado");
    if (nextFinalizado) {
      stack.push({ result: "(SE Atendimento finalizado)", indent: indent });
      stack.push({ id: nextFinalizado, indent: indent + 1 });
    }
    stack.push({ result: "(Fim SE)", indent: indent });
  },
};

export default instance;
