import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import AuthWrapper from './components/AuthWrapper';

import LoginPage from './pages/login/page';
import LogoutPage from './pages/logout/page';
import ChatPage from './pages/chat/page';
import CanalPage from './pages/canal/page';
import OnboardPage from './pages/onboard';
import DialogoPage from './pages/dialogo/page';
import DialogoEditPage from './pages/dialogo/edit';
import WebchatPage from './pages/webchat/page';
import AtalhoMensagemPage from './pages/atalho_mensagem/page';
import IntegracaoApiPage from './pages/integracao_api/page';
import FilaAtendimentoPage from './pages/fila_atendimento/page';
import RelatoriosPage from './pages/relatorios/page';
import RelatoriosVendasPage from './pages/relatorios/vendas';
import UsuariosPage from './pages/usuario/page';
import UsuarioGruposPage from './pages/usuario_grupo/page';
import HistoricoMensgensPage from './pages/historico_mensagens/page';
import PerfilPage from './pages/perfil/page';
import CalendarioPage from './pages/calendario/page';
import MensagemProgramadaPage from './pages/mensagem_programada/page';
import CampanhaMensagemPage from './pages/campanha_mensagem/page';
import ClientePage from './pages/cliente/page';
import MarcadorPage from './pages/marcador/page';
import FaturaPage from './pages/fatura/page';
import FaturaViewPage from './pages/fatura/view_fatura';
import BaseConhecimentoPage from './pages/base_conhecimento/page';
import BaseConhecimentoViewPage from './pages/base_conhecimento/view';
import IAAgentePage from './pages/ia_agente/page';
import ClienteGrupoPage from './pages/cliente_grupo/page';
import ProdutoPage from './pages/produto/page';
import VendaPage from './pages/venda/page';

import { QueryClient, QueryClientProvider } from 'react-query';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './theme-default.css';
import './index.css';
import { Navigate } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <AuthWrapper>
                <Navigate to="/chat" replace />
              </AuthWrapper>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/logout"
            element={
              <AuthWrapper>
                <LogoutPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/chat"
            element={
              <AuthWrapper>
                <ChatPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/canal"
            element={
              <AuthWrapper>
                <CanalPage />
              </AuthWrapper>
            }
          />
          <Route path="/onboard" element={<OnboardPage />} />
          <Route
            path="/dialogo"
            element={
              <AuthWrapper>
                <DialogoPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/dialogo/:id"
            element={
              <AuthWrapper>
                <DialogoEditPage />
              </AuthWrapper>
            }
          />
          <Route path="/webchat" element={<WebchatPage />} />
          <Route
            path="/atalho_mensagem"
            element={
              <AuthWrapper>
                <AtalhoMensagemPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/integracao_api"
            element={
              <AuthWrapper>
                <IntegracaoApiPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/fila_atendimento"
            element={
              <AuthWrapper>
                <FilaAtendimentoPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/relatorios"
            element={
              <AuthWrapper>
                <RelatoriosPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/relatorios/vendas"
            element={
              <AuthWrapper>
                <RelatoriosVendasPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/usuario"
            element={
              <AuthWrapper>
                <UsuariosPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/usuario_grupo"
            element={
              <AuthWrapper>
                <UsuarioGruposPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/historico_mensagens"
            element={
              <AuthWrapper>
                <HistoricoMensgensPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/perfil"
            element={
              <AuthWrapper>
                <PerfilPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/calendario"
            element={
              <AuthWrapper>
                <CalendarioPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/mensagem_programada"
            element={
              <AuthWrapper>
                <MensagemProgramadaPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/campanha_mensagem"
            element={
              <AuthWrapper>
                <CampanhaMensagemPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/cliente"
            element={
              <AuthWrapper>
                <ClientePage />
              </AuthWrapper>
            }
          />
          <Route
            path="/marcador"
            element={
              <AuthWrapper>
                <MarcadorPage />
              </AuthWrapper>
            }
          />

          <Route
            path="/fatura"
            element={
              <AuthWrapper>
                <FaturaPage />
              </AuthWrapper>
            }
          />

          <Route
            path="/fatura/:id"
            element={
              <AuthWrapper>
                <FaturaViewPage />
              </AuthWrapper>
            }
          />

          <Route
            path="/base_conhecimento"
            element={
              <AuthWrapper>
                <BaseConhecimentoPage />
              </AuthWrapper>
            }
          />

          <Route
            path="/base_conhecimento/:id/view"
            element={
              <AuthWrapper>
                <BaseConhecimentoViewPage />
              </AuthWrapper>
            }
          />

          <Route
            path="/ia_agente"
            element={
              <AuthWrapper>
                <IAAgentePage />
              </AuthWrapper>
            }
          />

          <Route
            path="/cliente_grupo"
            element={
              <AuthWrapper>
                <ClienteGrupoPage />
              </AuthWrapper>
            }
          />

          <Route
            path="/produto"
            element={
              <AuthWrapper>
                <ProdutoPage />
              </AuthWrapper>
            }
          />

          <Route
            path="/venda"
            element={
              <AuthWrapper>
                <VendaPage />
              </AuthWrapper>
            }
          />
        </Routes>
      </Router>
    </QueryClientProvider>
    <ToastContainer />
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
