import { MarcadorDto } from '@tera/shared/src/dto/MarcadorDto';
import Marcador from './Marcador';
import HBox from './HBox';
import Icon from '@mdi/react';
import { mdiTrashCan } from '@mdi/js';
import { useQuery } from 'react-query';
import axios from 'axios';

type Props = {
  ids: number[];
  onRemove?: (id: number) => void;
};

const Marcadores = ({ ids, onRemove }: Props) => {
  const qTags = useQuery('tags', async () => {
    const result = await axios.get('/api/marcador/box');
    return result.data?.result;
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        flexWrap: 'wrap',
      }}
    >
      {ids?.map((mid, idx) => {
        const tag = qTags.data?.find((t: MarcadorDto) => +t.id == +mid);
        if (!tag) return null;

        return (
          <Marcador
            color={tag.cor}
            key={idx}
            onClick={onRemove ? () => onRemove?.(mid) : undefined}
          >
            <HBox style={{ alignItems: 'center' }}>
              <span>{tag.nome}</span>
              {onRemove && (
                <span
                  style={{
                    color: 'var(--tc-color-danger)',
                    lineHeight: '12px',
                  }}
                >
                  <Icon path={mdiTrashCan} size="12px" />
                </span>
              )}
            </HBox>
          </Marcador>
        );
      })}
    </div>
  );
};

export default Marcadores;
