import React, { useEffect } from 'react';
import styles from './CampoMultiSeletor.module.css';
import Icon from '@mdi/react';
import {
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiCloseCircle,
  mdiDeleteCircle,
  mdiDeleteCircleOutline,
  mdiMagnify,
} from '@mdi/js';
import CampoTexto from './CampoTexto';
import ReactDOMServer from 'react-dom/server';
import HBox from '../layout/HBox';
import ReactDOM from 'react-dom';
import { set } from 'date-fns';
import Botao from '../Botao';

export type CampoMultiSeletorOption = {
  id: string;
  label: React.ReactNode;
  group?: string;
  error?: boolean;
};

type CampoMultiSeletorOptionsProps = {
  selectedIds: string[];
  onChangeSelectedIds: (index: string[]) => void;
  value: CampoMultiSeletorOption[];

  noSearch?: boolean;
  search: string;
  onChangeSearch: (value: string) => void;
};

export const CampoMultiSeletorOptions = ({
  selectedIds,
  onChangeSelectedIds,
  value,
  noSearch,
  search,
  onChangeSearch,
}: CampoMultiSeletorOptionsProps) => {
  const filteredOptions =
    search.length > 0
      ? value?.filter(
          it =>
            !it.label ||
            ReactDOMServer.renderToString(it.label)
              .toLowerCase()
              .includes(search.toLowerCase()),
        )
      : value;

  const groups = filteredOptions?.reduce<{
    [key: string]: CampoMultiSeletorOption[];
  }>((acc, it) => {
    if (it.group) {
      acc[it.group] = acc[it.group] ?? [];
      acc[it.group].push(it);
    } else {
      acc[''] = acc[''] ?? [];
      acc[''].push(it);
    }
    return acc;
  }, {});

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {!noSearch && (
        <CampoTexto
          prepend={
            <Icon path={mdiMagnify} size={1} color="var(--tc-color-gray-400)" />
          }
          placeholder="Pesquisar"
          onClick={(e: any) => {
            console.log('input click');
          }}
          value={search}
          onChange={onChangeSearch}
        />
      )}
      {search.length && !filteredOptions?.length ? (
        <div className={styles.noResults}>(Nenhum resultado encontrado)</div>
      ) : null}
      {groups
        ? Object.entries(groups).map(([group, gOptions], gidx) => {
            return (
              <div key={gidx}>
                {group && <div className={styles.group}>{group}</div>}
                {gOptions.map((option, index) => (
                  <div
                    tabIndex={0}
                    key={option.id}
                    className={[
                      styles.option,
                      selectedIds.includes(option.id) ? styles.selected : null,
                      group.length > 0 ? styles.grouped : null,
                      option.error ? styles.error : null,
                    ].join(' ')}
                    onClick={() => {
                      onChangeSelectedIds(
                        selectedIds.includes(option.id)
                          ? selectedIds.filter(it => it !== option.id)
                          : [...selectedIds, option.id],
                      );
                    }}
                  >
                    <span style={{ minWidth: '32px', marginRight: '4px' }}>
                      <Icon
                        path={selectedIds.includes(option.id) ? mdiCheck : ''}
                        size="14px"
                        color="var(--tc-color-primary)"
                      />
                    </span>
                    <span>{option.label}</span>
                  </div>
                ))}
              </div>
            );
          })
        : null}
    </div>
  );
};

type CampoMultiSeletorProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  label?: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
  options: CampoMultiSeletorOption[];
  disabled?: boolean;
  error?: string;
  noSearch?: boolean;
  [key: string]: any;
};

export default function CampoMultiSeletor({
  value,
  onChange,
  label,
  placeholder,
  type = 'text',
  required,
  options,
  disabled,
  error,
  noSearch,
  ...props
}: Readonly<CampoMultiSeletorProps>) {
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState<string>('');

  const selectedIndices = selectedIds.map(it =>
    options.findIndex(opt => opt.id === it),
  );

  const [dropdownPosition, setDropdownPosition] = React.useState<'down' | 'up'>(
    'down',
  );

  const filteredOptions =
    search.length > 0
      ? options?.filter(
          it =>
            !it.label ||
            ReactDOMServer.renderToString(it.label)
              .toLowerCase()
              .includes(search.toLowerCase()),
        )
      : options;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const optionsContainerRef = React.useRef<HTMLDivElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (disabled) return;

    let hit = true;
    switch (e.code.toLowerCase()) {
      case 'space':
      case 'enter':
        setOpen(!open);
        break;
      case 'arrowdown':
        setOpen(true);
        break;
      case 'arrowup':
        setOpen(true);
        break;
      default:
        hit = false;
    }
    if (hit) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleContainerClick = (e: any) => {
    if (disabled || e.target.closest(`.${styles.options}`)) {
      e.stopPropagation();
      return;
    }

    setOpen(!open);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        e.target instanceof HTMLElement &&
        e.target.closest(`.${styles.container}`)
      ) {
        e.stopPropagation();
        return;
      }
      setOpen(false);
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (value) {
      setSelectedIds(value);
    } else {
      setSelectedIds([]);
    }
  }, [value, filteredOptions]);

  const handleSelectedIds = (ids: string[]) => {
    setSelectedIds(ids);
    if (onChange) {
      onChange(ids);
    }
  };

  const removeSelected = (id: string) => {
    handleSelectedIds(selectedIds.filter(it => it !== id));
  };

  const [offset, setOffset] = React.useState<{
    top: number | undefined;
    left: number;
    width: string;
  }>({
    top: 0,
    left: 0,
    width: 'auto',
  });
  useEffect(() => {
    const rootEl = document.getElementById('root')!;
    const rootRect = rootEl.getBoundingClientRect();
    const adjust = () => {
      if (open) {
        const rect = containerRef.current!.getBoundingClientRect();
        const optionsRect =
          optionsContainerRef.current?.getBoundingClientRect()!;

        const overflow = rect.bottom + optionsRect.height - rootRect.height;
        const position = overflow > 0 ? 'up' : 'down';
        setDropdownPosition(position);

        if (position == 'up') {
          setOffset({
            top: Math.max(
              rootEl.scrollTop,
              rect.top + rootEl.scrollTop - optionsRect.height - 12,
            ),
            left: rect.left + rootEl.scrollLeft + 12,
            width: rect.width + 'px',
          });
        } else {
          setOffset({
            top: Math.min(
              rootRect.height + 112,
              rect.bottom + rootEl.scrollTop,
            ),
            left: rect.left + rootEl.scrollLeft + 12,
            width: rect.width + 'px',
          });
        }
      }
    };
    rootEl.addEventListener('scroll', adjust);
    rootEl.addEventListener('resize', adjust);
    adjust();

    return () => {
      rootEl.removeEventListener('scroll', adjust);
      rootEl.removeEventListener('resize', adjust);
    };
  }, [open]);

  return (
    <div>
      <label>{label}</label>
      <div
        ref={containerRef}
        className={[
          styles.container,
          error ? styles.error : null,
          disabled ? styles.disabled : null,
        ].join(' ')}
        tabIndex={disabled ? undefined : 0}
        onKeyDown={handleKeyDown}
        onClick={handleContainerClick}
      >
        {placeholder && !selectedIds.length && (
          <div className={styles.placeholder}>{placeholder}</div>
        )}
        <div className={styles.selectedContainer}>
          <HBox>
            {selectedIndices.map(sidx => (
              <div key={sidx} className={styles.chip}>
                <div className={styles.chipLabel}>
                  {options[sidx]?.label}
                  <button onClick={() => removeSelected(options[sidx].id)}>
                    <Icon path={mdiDeleteCircleOutline} size="16px" />
                  </button>
                </div>
              </div>
            ))}
          </HBox>
        </div>
        {open &&
          ReactDOM.createPortal(
            <div
              ref={optionsContainerRef}
              className={[styles.options].join(' ')}
              style={{
                top: offset.top,
                left: offset.left,
                width: offset.width,
              }}
            >
              <HBox style={{ justifyContent: 'flex-end' }}>
                <Botao variant="none-secondary" onClick={() => setOpen(false)}>
                  <HBox>
                    Fechar
                    <Icon path={mdiCloseCircle} size={1} />
                  </HBox>
                </Botao>
              </HBox>
              <CampoMultiSeletorOptions
                value={filteredOptions}
                noSearch={noSearch}
                search={search}
                onChangeSearch={setSearch}
                selectedIds={selectedIds}
                onChangeSelectedIds={handleSelectedIds}
              />
            </div>,
            document.querySelector('#root')!,
          )}

        <div className={styles.arrow}>
          <Icon path={open ? mdiChevronUp : mdiChevronDown} size={1} />
        </div>
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
