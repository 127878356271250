import Icon from '@mdi/react';
import HBox from '../layout/HBox';
import styles from './NavItem.module.css';
import { Link, useNavigate } from 'react-router-dom';

const NavLink = ({ path, children }: any) => {
  const navigate = useNavigate();

  if (path.indexOf('http') === 0) {
    return (
      <a href={path} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return (
    <Link
      to={path}
      // onMouseUp={e => {
      //   e.preventDefault();
      //   navigate(path);
      //   return false;
      // }}
    >
      {children}
    </Link>
  );
};

const NavItem = ({ value, selected }: any) => {
  return (
    <li
      className={[
        styles.container,
        value.important ? styles.emphasis : null,
      ].join(' ')}
    >
      <NavLink path={value?.path}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: value.important ? '8px' : 0,
            borderRadius: '8px',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <Icon
            path={value.icon}
            size={value.important ? 1.2 : 0.8}
            color="var(--tc-color-brand-primary)"
          />
          <span className="link_name">{value.label}</span>
        </div>
      </NavLink>
    </li>
  );
};

export default NavItem;
