import Titulo from '../../components/tipografia/Titulo';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import { DragDropContext } from 'react-beautiful-dnd';
import { CSSProperties, useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import SalaEspera from './SalaEspera';
import { toast } from 'react-toastify';
import Grid, { GridItem } from '../../components/layout/Grid';
import CampoMultiSeletor from '../../components/Formulario/CampoMultiSeletor';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import { useDebounce } from '../chat/useDebounce';
import FiltroGrupoUsuario from '../../components/Filtros/FiltroGrupoUsuario';

type Query = {
  grupo_id?: [string, any];
  online?: [string, any];
};

const reorder = (list: any, startIndex: number, endIndex: number): any => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any,
): any => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  if (removed) destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

type PageProps = {};

type FilterProps = {
  somente_online: boolean;
  grupo_id: number[];
};

const Page = ({}: PageProps) => {
  const [dndList, setDndList] = useState<any>({});
  const [dragItem, setDragItem] = useState(null);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const [queryGroupStatus, setQueryGroupStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');

  const [filter, setFilter] = useState<FilterProps>({
    somente_online: true,
    grupo_id: [],
  });

  const queryUsers = useQuery(
    ['usuarios', filter, debouncedSearch],
    async () => {
      const fi: Query = {};
      const q: Record<string, string> = {};

      if (filter?.grupo_id) {
        fi.grupo_id = ['in', filter.grupo_id];
      }

      if (filter?.somente_online) {
        q.somente_online = 'true';
      }

      const res = await fetch(
        `/api/usuario?t=200&txt=${encodeURIComponent(debouncedSearch)}&fi=${encodeURIComponent(JSON.stringify(fi))}&q=${encodeURIComponent(JSON.stringify(q))}`,
      );
      return res.json();
    },
    {
      staleTime: 1000 * 60 * 15,
      enabled: !dragItem,
    },
  );

  const queryList = useQuery(
    ['chat/abertos'],
    async () => {
      const res = await fetch(`/api/chat/abertos`);
      return res.json();
    },
    {
      enabled:
        queryUsers.isSuccess && queryGroupStatus == 'success' && !dragItem,
      staleTime: 1000 * 10,
      onSuccess(data) {
        buildDndList(data.result);
      },
      onSettled(data, error) {
        console.log('onSettled', data, error);
      },
    },
  );

  const chatChgMutator = useMutation(
    async (data: { id: number }) => {
      const res = await fetch(`/api/chat/prioridades`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) throw new Error('Erro ao alterar prioridade');

      return res.json();
    },
    {
      onSettled: async () => {
        setDragItem(null);
        queryList.refetch();
      },
      onSuccess: async () => {
        toast('Atendimento alterado com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      },
      onError: async () => {
        toast('Erro ao alterar atendimento. Tente novamente mais tarde.', {
          toastId: 'error',
          position: 'top-center',
          type: 'error',
          autoClose: 5000,
        });
      },
    },
  );

  const buildDndList = useCallback(
    (list: any) => {
      console.log('updating');
      const _list: any = {
        aguardando_atendente:
          list?.filter(
            (it: any) =>
              it.fila_usuario_responsavel_id == null &&
              it.fila_usuario_responsavel_id == null,
          ) ?? [],
      };

      if (queryUsers?.data?.result) {
        for (const user of queryUsers.data.result) {
          _list[`em_atendimento-${user.id}`] =
            list?.filter((it: any) =>
              it.estado == 'atendimento'
                ? it.usuario_responsavel_id == user.id
                : it.fila_usuario_responsavel_id == user.id,
            ) ?? [];
        }
      }

      setDndList(_list);
    },
    [queryList.data?.result, queryUsers.data?.result],
  );

  const onDragStart = useCallback(
    (result: any) => {
      setDragItem(dndList?.[result.source.droppableId]?.[result.source.index]);
    },
    [dndList],
  );

  const onDragEnd = useCallback(
    (result: any) => {
      const { source, destination, draggableId } = result;

      // dropped outside the list
      if (!destination) {
        setDragItem(null);
        return;
      }

      let response: any = {};

      if (source.droppableId === destination.droppableId) {
        const items = reorder(
          dndList[source.droppableId],
          source.index,
          destination.index,
        );

        response[source.droppableId] = items;
      } else {
        response = move(
          dndList[source.droppableId],
          dndList[destination.droppableId],
          source,
          destination,
        );
      }

      const [fila_id, chat_id] = draggableId.split('-');

      const obj = {
        id: parseInt(chat_id),
        prioridade_atendimento_de: source.index,
        prioridade_atendimento_para: destination.index,
        usuario_responsavel_id: parseInt(destination.droppableId.split('-')[1]),
      };

      chatChgMutator.mutate(obj);

      setDndList({
        ...(dndList ?? {}),
        ...(response ?? {}),
      });
    },
    [dndList],
  );
  return (
    <LayoutUsuario>
      <div style={{ overflow: 'auto' }}>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
            {/* Header */}
            <HBox>
              <VBox>
                <Titulo>Filas de atendimento</Titulo>
                <Descricao>
                  Gerencie aqui quais clientes serão atendidos por quais
                  atendentes
                </Descricao>
              </VBox>
              {/* <HBox gap="12px" className={styles.headerAcoes}>
                <Botao variant="outline-primary">Ação secundária</Botao>
                <Botao variant="primary">Ação principal</Botao>
              </HBox> */}
            </HBox>

            {/* Filtros */}
            <Grid>
              <GridItem sm={12} md={4}>
                <CampoTexto
                  value={search}
                  onChange={setSearch}
                  prepend={
                    <Icon
                      path={mdiMagnify}
                      size={1}
                      color="var(--tc-color-gray-500)"
                    />
                  }
                  placeholder="Pesquisar"
                />
              </GridItem>
              <GridItem sm={12} md={4}>
                <FiltroGrupoUsuario
                  value={filter.grupo_id.map(it => it.toString())}
                  onChange={value =>
                    setFilter({
                      ...filter,
                      grupo_id: value.map(it => parseInt(it)),
                    })
                  }
                  disableQuery={!!dragItem}
                  onQueryStatusChanged={setQueryGroupStatus}
                />
              </GridItem>
              <GridItem sm={12} md={4}>
                <CampoSeletor
                  options={[
                    { id: 'online', label: 'Somente atendentes online' },
                    { id: 'all', label: 'Todos os atendentes' },
                  ]}
                  value={filter.somente_online ? 'online' : 'all'}
                  onChange={value =>
                    setFilter({
                      ...filter,
                      somente_online: value === 'online',
                    })
                  }
                  noSearch
                />
              </GridItem>
            </Grid>

            {/* Conteudo */}
            <VBox gap="16px" style={{ overflow: 'auto' }}>
              <SalaEspera
                droppableId="aguardando_atendente"
                dragItem={dragItem}
                nome="Sala de espera"
                descricao="Atend. automático"
                clientes={dndList['aguardando_atendente']}
              />

              {queryUsers.data?.result?.map((user: any) => (
                <SalaEspera
                  key={`card-${user.id}`}
                  dragItem={dragItem}
                  nome={user.nome}
                  online={user.online}
                  droppableId={`em_atendimento-${user.id}`}
                  descricao={`Depto. ${user.grupo?.nome}`}
                  clientes={dndList[`em_atendimento-${user.id}`]}
                />
              ))}
            </VBox>
          </VBox>
        </DragDropContext>
      </div>
    </LayoutUsuario>
  );
};

export default Page;
