import Titulo from '../../components/tipografia/Titulo';
import React from 'react';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import {
  mdiAccount,
  mdiHeadset,
  mdiLoading,
  mdiMagnify,
  mdiRobot,
} from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { useDebounce } from '../chat/useDebounce';
import { format } from 'date-fns';
import IconeCanal from '../../components/layout/IconeCanal';
import Grid, { GridItem } from '../../components/layout/Grid';
import CampoData from '../../components/Formulario/CampoData';
import { FiltroCanais } from './FiltroCanais';
import { FiltroTipoMensagem } from './FiltroTipoMensagem';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type PageProps = {};

const Page = ({}: PageProps) => {
  const [search, setSearch] = React.useState<string>('');
  const [filter, setFilter] = React.useState<any>({});
  const [filterDataIni, setFilterDataIni] = React.useState<string>('');
  const [filterDataFim, setFilterDataFim] = React.useState<string>('');
  const [filterCanais, setFilterCanais] = React.useState<string[]>([]);

  const [filterTipoMensagem, setFiltroTipoMensagem] = React.useState<string[]>(
    [],
  );
  const [filterIdsNotificacao, setFilterIdsNotificacao] = React.useState<
    string[]
  >([]);

  const debouncedSearch = useDebounce(search, 500);

  const headers: DataTableHeader[] = [
    {
      label: '# Atend.',
      width: '5%',
      column: 'chat_id',
    },
    {
      label: '# Cliente',
      width: '5%',
      render: row => (
        <span>
          <IconeCanal tipo={row.canal_tipo} style={{ height: '16px' }} />
          {row.canal_cliente_id}
        </span>
      ),
    },
    {
      label: 'Cliente',
      width: '10%',
      render: row => <span>{row.nome}</span>,
    },
    {
      label: 'Mensagem',
      width: '40%',
      render: row => (
        <div>
          {row.origem === 'bot' && (
            <Icon
              path={mdiRobot}
              size="14px"
              color="var(--tc-color-gray-500)"
              title="Chatbot"
            />
          )}
          {row.origem === 'usuario' && (
            <Icon
              path={mdiAccount}
              size="14px"
              color="var(--tc-color-primary)"
              title="Cliente"
            />
          )}
          {row.origem === 'atendente' && (
            <Icon
              path={mdiHeadset}
              size="14px"
              color="var(--tc-color-warning)"
              title="Atendente"
            />
          )}{' '}
          {row.tipo_mime === 'text/message' ? row.conteudo_ou_url : '(arquivo)'}
        </div>
      ),
    },
    {
      label: 'Atendente responsável',
      width: '10%',
      column: 'usuario_responsavel_nome',
    },
    {
      label: 'Data',
      width: '10%',
      render: row => format(new Date(row.data_criacao), 'dd/MM/yyyy HH:mm'),
    },
    // {
    //   label: 'Ações',
    //   width: '100px',
    //   stickRight: true,
    //   alignRight: true,
    //   render: (row: any) => {
    //     return (
    //       <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
    //         <Botao
    //           variant="none-primary"
    //           icon={mdiPen}
    //           onClick={() => setEditObject(row)}
    //         />
    //         <BotaoPerigoso
    //           variant="none-danger"
    //           icon={mdiTrashCan}
    //           onClick={() => handleDelete(row.id)}
    //         />
    //       </HBox>
    //     );
    //   },
    // },
  ];

  const queryList = useInfiniteQuery({
    queryKey: ['historico_mensagens'],
    queryFn: async ({ pageParam }) => {
      const where: Record<string, any> = {};
      if (filterDataIni && filterDataFim) {
        where['chat_mensagem.data_criacao'] = [
          'between',
          [filterDataIni, filterDataFim],
        ];
      } else if (filterDataIni) {
        where['chat_mensagem.data_criacao'] = ['ge', new Date(filterDataIni)];
      } else if (filterDataFim) {
        where['chat_mensagem.data_criacao'] = ['le', filterDataFim];
      }

      if (filterCanais.length > 0) {
        where['chat.canal_id'] = ['in', filterCanais];
      }

      if (filterTipoMensagem.length === 1) {
        if (filterTipoMensagem[0] === 'message')
          where['chat_mensagem.mensagem_programada_id'] = ['eq', null];
        else if (filterTipoMensagem[0] === 'notification')
          where['chat_mensagem.mensagem_programada_id'] = ['ne', null];
      }

      if (filterIdsNotificacao.length > 0) {
        where['chat_mensagem.mensagem_programada_id'] = [
          'in',
          filterIdsNotificacao,
        ];
      }

      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
        fi: JSON.stringify(where),
      });

      return await axios.get('/api/chat_mensagem?' + params.toString(), {
        withCredentials: true,
      });
    },
    getNextPageParam: lastPage => lastPage.data?.cursor ?? null,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 0,
  });

  const total = queryList.data?.pages?.[0]?.data?.total ?? 0;

  return (
    <LayoutUsuario>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Histórico de mensagens</Titulo>
            <Descricao>
              Pesquise aqui as mensagens trocadas no sistema
            </Descricao>
          </VBox>
        </HBox>

        {/* Filtros */}
        <Grid>
          <GridItem sm={12} md={6}>
            <CampoTexto
              label="&nbsp;"
              prepend={
                <Icon
                  path={mdiMagnify}
                  size={1}
                  color="var(--tc-color-gray-500)"
                />
              }
              placeholder="Pesquisar"
              value={search}
              onChange={setSearch}
            />
          </GridItem>
          <GridItem sm={12} md={3} lg={2}>
            <CampoData
              type="datetime-local"
              label="Data início"
              value={filterDataIni}
              onChange={setFilterDataIni}
            />
          </GridItem>
          <GridItem sm={12} md={3} lg={2}>
            <CampoData
              type="datetime-local"
              label="Data fim"
              value={filterDataFim}
              onChange={setFilterDataFim}
            />
          </GridItem>

          <GridItem sm={12} md={6} lg={2}>
            <FiltroTipoMensagem
              value={filterTipoMensagem}
              onChange={setFiltroTipoMensagem}
              idValues={filterIdsNotificacao}
              onChangeValues={setFilterIdsNotificacao}
            />
          </GridItem>
          <GridItem sm={12}>
            <FiltroCanais value={filterCanais} onChange={setFilterCanais} />
          </GridItem>

          <GridItem sm={12}>
            <Botao variant="primary" onClick={() => queryList.refetch()}>
              Aplicar
            </Botao>
          </GridItem>
        </Grid>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo{' '}
            {queryList.data?.pages?.reduce(
              (acc, page) => acc + (page.data?.result?.length ?? 0),
              0,
            ) ?? '0'}{' '}
            de {+total} resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
          paginas={queryList.data?.pages ?? []}
          aoObterPagina={(page: any): any[] => page.data.result}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
