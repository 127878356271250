import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "delay",
  label: "Atraso (delay)",
  description: "Aguarda um tempo antes de continuar o fluxo",
  category: "Fluxo",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "delay", name: "delay", label: "Tempo de atraso" },
    ];
  },
  outputs() {
    return [{ type: "route", name: "route", label: "Saida fluxo" }];
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: `[Atraso ${inputValues.delay}s]`, indent });

    const next = nextNodeId(nodeId, "route");
    if (next) {
      stack.push({ id: next, indent });
    }
  },
};

export default instance;
