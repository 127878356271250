import React, { useEffect, useRef } from 'react';
import styles from './Mensagens.module.css';
import Botao from '../../components/Botao';
import {
  mdiAccountPlus,
  mdiArrowLeft,
  mdiArrowRight,
  mdiChatProcessing,
  mdiCheckCircle,
  mdiClose,
  mdiHeadset,
  mdiLoading,
  mdiMessage,
  mdiRobot,
  mdiTagPlus,
  mdiThumbDown,
  mdiThumbUp,
} from '@mdi/js';
import Icon from '@mdi/react';
import Badge from '../../components/layout/Badge';
import HBox from '../../components/layout/HBox';
import { ChatMensagemDto } from '@tera/shared/src/dto/ChatMensagemDto';
import ChatDto, { ChatDtoEstado } from '@tera/shared/src/dto/ChatDto';
import TextoTelefone from '../../components/layout/TextoTelefone';
import IconeCanal from '../../components/layout/IconeCanal';
import Footer from './footer/Footer';
import VBox from '../../components/layout/VBox';
import Mensagem from './Mensagem';
import Modal from '../../components/layout/Modal';
import CampoSeletor, {
  CampoSeletorOption,
  CampoSeletorOptions,
} from '../../components/Formulario/CampoSeletor';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BadgeMarcadores } from './BadgeMarcadores';
import { useAuth } from '@/lib/useAuth';
import Marcadores from '@/components/layout/Marcadores';
import { FormNovoCliente } from './FormNovoCliente';

// const dt = Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
type Props = {
  chat: ChatDto;
  value: ChatMensagemDto[];
  onChatEstadoChange: (estado: ChatDtoEstado, solucionado?: boolean) => void;
  onChatAddTag: (chatId: number, tagId: number) => void;
  onSend: (mensagem: string, anexos: File[]) => void;
  isSending: boolean;
  onSendAudio: (audio: Blob) => void;
  onLoadMore: (cursor: string) => void;
  isLoading: boolean;
  hasMorePages: boolean;
  onReloadChats: () => void;
  onSelectedKbSection: (selection: any) => void;
  onGoBack: () => void;
};
const Mensagens = ({
  chat,
  value,
  onChatEstadoChange,
  onChatAddTag,
  onSend,
  isSending,
  onSendAudio,
  onLoadMore,
  isLoading,
  hasMorePages,
  onReloadChats,
  onSelectedKbSection,
  onGoBack,
}: Props) => {
  const { user } = useAuth();
  const [showTrocarAtendente, setShowTrocarAtendente] = React.useState(false);
  const [atendenteSelecionado, setAtendenteSelecionado] =
    React.useState<any>(null);

  const containerScrollRef = useRef<any>();

  const fetching = useRef<boolean>(false);
  useEffect(() => {
    const handleScroll = async (e: any) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;

      if (
        !fetching.current &&
        !isLoading &&
        scrollHeight + scrollTop <= clientHeight * 1.2 &&
        hasMorePages
      ) {
        fetching.current = true;
        onLoadMore(value?.[value.length - 1]?.data_criacao!);
      }
    };

    const csr = containerScrollRef.current;
    csr?.addEventListener('scroll', handleScroll);
    return () => {
      csr?.removeEventListener('scroll', handleScroll);
    };
  }, [hasMorePages, value, chat, onLoadMore, isLoading]);

  useEffect(() => {
    if (!isLoading) fetching.current = false;
  }, [isLoading]);

  const chatChanged = useRef(false);
  useEffect(() => {
    console.log('chat updated', chat, containerScrollRef.current);
    if (chat != null) {
      chatChanged.current = false;
      containerScrollRef.current.scrollTo({
        top: 0,
      });
    }
  }, [chat]);

  const handleCloseModal = () => {
    setShowTrocarAtendente(false);
  };

  const handleOpenModal = () => {
    setShowTrocarAtendente(true);
    setAtendenteSelecionado(null);

    setAtendSelIdx(-1);
    setAtendSearch('');
  };

  const chgAtendenteMutator = useMutation(
    (atendenteId: string) => {
      return axios.post(`/api/chat/${chat.id}/atendente`, { atendenteId });
    },
    {
      onSuccess: data => {
        console.log('atendente changed', data);
        setShowTrocarAtendente(false);
        toast('Atendente alterado com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      },
      onError: error => {
        console.error(error);
        toast('Erro ao alterar atendente', {
          toastId: 'error',
          position: 'top-center',
          type: 'error',
          autoClose: 5000,
        });
      },
    },
  );

  const handleConfirmModal = () => {
    chgAtendenteMutator.mutate(atendenteSelecionado);
  };

  const queryUsuarios = useQuery<any[]>('usuario', async () => {
    const result = await axios.get('/api/usuario/box');
    return result.data?.result;
  });

  const atendentes =
    queryUsuarios?.data
      ?.filter((it: any) => it.id !== user.id)
      ?.map(
        (it: any) =>
          ({
            id: it.id.toString(),
            label: it.nome,
            group: it.grupo?.nome,
          }) as CampoSeletorOption,
      ) ?? [];

  const [atendSelIdx, setAtendSelIdx] = React.useState<number>(-1);
  const [atendSearch, setAtendSearch] = React.useState<string>('');

  const [showAddTag, setShowAddTag] = React.useState(false);
  const [selectedTagId, setSelectedTagId] = React.useState<any>(null);

  const [showAddClient, setShowAddClient] = React.useState(false);
  const [newClient, setNewClient] = React.useState<any>(null);

  const qTags = useQuery('tags', async () => {
    const result = await axios.get('/api/marcador/box');
    return result.data?.result;
  });

  const optionsTags =
    qTags.data
      ?.filter((it: any) => ['qualquer', 'cliente'].includes(it.local))
      ?.map((it: any) => ({
        id: it.id.toString(),
        label: it.nome,
      })) ?? [];

  const addTagMutator = useMutation(
    (tagId: string) => {
      return axios.post(`/api/cliente/${chat.cliente?.id}/marcador/${tagId}`);
    },
    {
      onSuccess: () => {
        setShowAddTag(false);
        onReloadChats();
        toast('Marcador adicionado com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      },
      onError: error => {
        console.error(error);
        toast('Erro ao adicionar marcador', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const addClientMutator = useMutation(
    async () => {
      return axios.post('/api/chat/cliente', newClient);
    },
    {
      onSuccess: () => {
        toast('Cliente adicionado com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        onReloadChats();
        handleCloseModalNewClient();
      },
      onError: () => {
        toast('Erro ao adicionar cliente', {
          toastId: 'error',
          position: 'top-center',
          type: 'error',
          autoClose: 5000,
        });
      },
    },
  );

  const handleOpenModalNewClient = () => {
    setShowAddClient(true);
    setNewClient({
      nome: chat.nome,
      contato_tipo: chat.canal?.tipo == 'wa360' ? 'whatsapp' : chat.canal?.tipo,
      contato_valor: chat.canal_cliente_id,
      metadados: {},
    });
  };

  const handleCloseModalNewClient = () => {
    setShowAddClient(false);
    setNewClient(null);
  };

  const handleSaveNewClient = async () => {
    addClientMutator.mutate();
  };

  const [finalizando, setFinalizando] = React.useState(false);

  if (!chat) return null;

  return (
    <div className={styles.container}>
      <Modal
        size="md"
        show={showAddClient}
        onClose={handleCloseModalNewClient}
        onCancel={handleCloseModalNewClient}
        onConfirm={handleSaveNewClient}
        title="Dados do contato"
        confirmProps={{
          label: 'Confirmar',
          disabled: addClientMutator.isLoading,
        }}
        cancelProps={{
          disabled: addClientMutator.isLoading,
        }}
      >
        <FormNovoCliente
          enabled={showAddClient}
          value={newClient}
          onChange={setNewClient}
        />
      </Modal>

      <Modal
        show={showTrocarAtendente}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleConfirmModal}
        title="Trocar atendente"
        confirmProps={{
          disabled: !atendenteSelecionado || chgAtendenteMutator.isLoading,
        }}
        cancelProps={{
          disabled: chgAtendenteMutator.isLoading,
        }}
      >
        <VBox gap="16px">
          <p>
            Selecione para qual atendente deseja encaminhar este atendimento
          </p>

          <div className={styles.trocarAtendenteBox}>
            <HBox style={{ width: '100%', justifyContent: 'space-between' }}>
              <VBox style={{ width: 'auto' }} center>
                <div style={{ color: 'var(--tc-color-primary)' }}>
                  Atendente atual
                </div>
                <div>
                  {chat.usuario_responsavel_nome ?? chat.usuario_responsavel_id
                    ? chat.usuario_responsavel_id == user.id
                      ? user.nome + ' (você)'
                      : atendentes.find(
                          it =>
                            it.id === chat.usuario_responsavel_id?.toString(),
                        )?.label
                    : '(Não identificado)'}
                </div>
              </VBox>
              <Icon
                path={mdiArrowRight}
                size="24px"
                color="var(--tc-color-primary)"
              />
              <VBox style={{ width: 'auto' }} center>
                <div style={{ color: 'var(--tc-color-primary)' }}>
                  Novo atendente
                </div>
                <div>
                  {atendenteSelecionado
                    ? atendentes.find(it => it.id === atendenteSelecionado)
                        ?.label
                    : '(Selecione abaixo)'}
                </div>
              </VBox>
            </HBox>
          </div>

          <div
            style={{
              width: '100%',
              height: '100%',
              border: '1px solid var(--tc-color-gray-300)',
              borderRadius: '8px',
              padding: '8px',
            }}
          >
            <CampoSeletorOptions
              value={atendentes}
              onSelect={value => setAtendenteSelecionado(value)}
              selectedIndex={atendSelIdx}
              onChangeSelectedIndex={setAtendSelIdx}
              search={atendSearch}
              onChangeSearch={setAtendSearch}
            />
          </div>
        </VBox>
      </Modal>

      <Modal
        show={showAddTag}
        onClose={() => setShowAddTag(false)}
        onCancel={() => setShowAddTag(false)}
        onConfirm={() => {
          addTagMutator.mutateAsync(selectedTagId);
        }}
        title="Adicionar marcador ao cliente"
        confirmProps={{
          disabled: addTagMutator.isLoading,
          label: 'Adicionar',
        }}
        cancelProps={{
          disabled: addTagMutator.isLoading,
        }}
      >
        <CampoSeletor
          options={optionsTags.filter(
            (it: any) => !chat.cliente?.marcadores?.includes(+it.id),
          )}
          value={selectedTagId?.toString()}
          onChange={(value: string) => setSelectedTagId(+value)}
          placeholder="Selecione um marcador"
        />
      </Modal>
      <div className={styles.headerVoltar}>
        <Botao
          variant="none-primary"
          onClick={() => onGoBack()}
          icon={mdiArrowLeft}
        >
          Voltar
        </Botao>
      </div>
      <div className={styles.header}>
        <div className={styles.column1}>
          <div className={styles.nome}>
            <HBox>
              <IconeCanal
                tipo={chat.canal?.tipo ?? 'whatsapp'}
                style={{ width: '24x', height: '24px' }}
              />
              <span
                style={{
                  flex: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {chat.cliente?.nome ?? chat.nome}
              </span>
              {chat.cliente?.id > 0 && (
                <Botao
                  variant="none-primary"
                  icon={mdiTagPlus}
                  title="Adicionar marcador ao contato"
                  onClick={() => setShowAddTag(true)}
                />
              )}
              {!chat.cliente?.id && (
                <Botao
                  variant="none-primary"
                  icon={mdiAccountPlus}
                  title="Adicionar contato"
                  onClick={handleOpenModalNewClient}
                />
              )}
              <Marcadores ids={chat.cliente?.marcadores} />
            </HBox>
          </div>
          <div className={styles.contato}>
            {['whatsapp', 'wa360'].includes(chat.canal?.tipo ?? 'whatsapp') && (
              <TextoTelefone formatar value={chat.canal_cliente_id!} />
            )}
            {!['whatsapp', 'wa360'].includes(
              chat.canal?.tipo ?? 'whatsapp',
            ) && (
              <span>
                {chat.canal_cliente_id} - [#{chat.id}]
              </span>
            )}
          </div>
          <div className={styles.canal}>{chat.canal?.nome}</div>
        </div>
        <div className={styles.column2}>
          {chat.estado === 'chatbot' && (
            <>
              <div>
                <Botao
                  variant="outline-primary"
                  icon={mdiHeadset}
                  onClick={() => onChatEstadoChange('atendimento')}
                >
                  Atender
                </Botao>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}
              >
                {chat.marcadores && chat.marcadores.length > 0 && (
                  <BadgeMarcadores marcadores={chat.marcadores} />
                )}

                <Badge variant="info">
                  <Icon path={mdiRobot} size="12px" />
                  Bot em ação
                </Badge>
              </div>
            </>
          )}
          {['atendimento', 'nao_respondido'].includes(chat.estado) && (
            <>
              <HBox gap="12px" className={styles.botoesAtendimento}>
                <Botao
                  variant="none-primary"
                  icon={mdiHeadset}
                  onClick={handleOpenModal}
                >
                  Trocar atendente
                </Botao>

                {!finalizando && (
                  <Botao
                    variant="outline-primary"
                    icon={mdiCheckCircle}
                    onClick={() => setFinalizando(true)}
                  >
                    Finalizar
                  </Botao>
                )}
                {finalizando && (
                  <React.Fragment>
                    <Botao
                      variant="success"
                      icon={mdiThumbUp}
                      onClick={() => {
                        onChatEstadoChange('finalizado', true);
                        setFinalizando(false);
                      }}
                    >
                      Solucionado
                    </Botao>
                    <Botao
                      variant="secondary"
                      icon={mdiThumbDown}
                      onClick={() => {
                        onChatEstadoChange('finalizado', false);
                        setFinalizando(false);
                      }}
                    >
                      Não resolvido
                    </Botao>
                    <Botao
                      variant="none-primary"
                      icon={mdiClose}
                      onClick={() => setFinalizando(false)}
                    ></Botao>
                  </React.Fragment>
                )}
              </HBox>
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}
              >
                {chat.marcadores && chat.marcadores.length > 0 && (
                  <BadgeMarcadores marcadores={chat.marcadores} />
                )}

                <Badge variant="warning">
                  <Icon path={mdiHeadset} size="12px" />
                  {chat.usuario_responsavel_nome}
                </Badge>
              </div>
            </>
          )}
          {['finalizado'].includes(chat.estado) && (
            <>
              <div>
                <Botao
                  variant="outline-primary"
                  icon={mdiMessage}
                  onClick={() => onChatEstadoChange('atendimento')}
                >
                  Reabrir
                </Botao>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}
              >
                {chat.marcadores && chat.marcadores.length > 0 && (
                  <BadgeMarcadores marcadores={chat.marcadores} />
                )}

                <Badge variant="primary">
                  <Icon path={mdiCheckCircle} size="12px" />
                  Finalizado
                </Badge>
              </div>
            </>
          )}
          {chat.estado === 'aguardando_cliente' && (
            <VBox center>
              <Icon
                path={mdiChatProcessing}
                size={'32px'}
                color="var(--tc-color-gray-400)"
              />
              <Badge variant="info"> Aguardando cliente... </Badge>
            </VBox>
          )}
        </div>
      </div>

      <span
        style={{
          visibility: isLoading ? 'visible' : 'hidden',
          position: 'absolute',
          top: '100px',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          textAlign: 'center',
          transform: 'translateX(-50%)',
          width: '2rem',
          height: '2rem',
          backgroundColor: '#fff',
          border: '1px solid #CCC',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icon path={mdiLoading} size="20px" spin={1} />
      </span>

      <div className={styles.body} ref={containerScrollRef}>
        {value?.map((item, index) => (
          <Mensagem
            item={item}
            index={index}
            mensagens={value}
            key={item.id}
            onSelectedKbSection={onSelectedKbSection}
          />
        ))}
      </div>
      <div className={[styles.footer].join(' ')}>
        <Footer
          chatId={chat.id}
          chatTags={chat.marcadores}
          onChatAddTag={onChatAddTag}
          estado={chat.estado}
          onSend={onSend}
          isSending={isSending}
          onSendAudio={onSendAudio}
        />
      </div>
    </div>
  );
};

export default Mensagens;
