import Titulo from '../../components/tipografia/Titulo';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiChartAreaspline, mdiMagnify } from '@mdi/js';
import Grid, { GridItem } from '@/components/layout/Grid';
import { Link } from 'react-router-dom';
import CampoData from '@/components/Formulario/CampoData';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useState } from 'react';
import { api } from '@/lib/apiCall';
import { CampoSeletorUsuarios } from '@/components/modelos/CampoSeletorUsuarios';

type PageProps = {};

const numFormat = new Intl.NumberFormat('pt-BR', {});

const currencyFormat = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const ValorRelatorio = ({ tipo, valor }: any) => {
  switch (tipo) {
    case 'boolean':
      return valor == true ? 'Sim' : 'Não';
    case 'currency':
      return `${currencyFormat.format(+valor)}`;
    case 'number':
      return `${numFormat.format(+valor)}`;
    case 'date':
      return new Date(valor).toLocaleDateString();
    case 'string':
    default:
      return valor;
  }
};

const Page = ({}: PageProps) => {
  const [filters, setFilters] = useState<any>({});

  const query = useQuery('relatorio/vendas', async () => {
    const result = await api.get(
      '/api/relatorios/vendas?filter=' +
        encodeURIComponent(JSON.stringify(filters)),
    );
    return result.data;
  });

  return (
    <LayoutUsuario>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Relatório de Vendas</Titulo>
          </VBox>
        </HBox>

        {/* Filtros */}
        <Grid>
          <GridItem sm={12} md={3} lg={2}>
            <CampoData
              label="Período inicial"
              value={filters.data_inicio}
              onChange={v => setFilters({ ...filters, data_inicio: v })}
            />
          </GridItem>
          <GridItem sm={12} md={3} lg={2}>
            <CampoData
              label="Período final"
              value={filters.data_fim}
              onChange={v => setFilters({ ...filters, data_fim: v })}
            />
          </GridItem>
          <GridItem sm={12} md={3}>
            <CampoSeletorUsuarios
              value={filters.responsavel}
              onChange={v => setFilters({ ...filters, responsavel: v })}
              label="Buscar por vendedores"
              placeholder="(Todos)"
            />
          </GridItem>
        </Grid>
        <div>
          <Botao
            variant="primary"
            onClick={() => query.refetch()}
            disabled={query.isFetching}
          >
            Aplicar
          </Botao>
        </div>

        <table className={styles.table}>
          <thead>
            <tr>
              {query.data?.columns?.map((col: any) => <th>{col.label}</th>)}
            </tr>
          </thead>
          <tbody>
            {query.data?.result?.map((row: any) => (
              <tr>
                {query.data?.columns?.map((col: any) => (
                  <td>
                    <ValorRelatorio tipo={col.type} valor={row[col.name]} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
