import styles from './NavItens.module.css';
import NavItem from './NavItem';

type Props = {
  value: any[];
  selected: string | null;
  setSelected: (value: string | null) => void;
  setIsOver: (value: boolean) => void;
};
const NavItens = ({ value, selected, setSelected, setIsOver }: Props) => {
  return (
    <div
      className={styles.container}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
    >
      {value?.map((categoria, index) => (
        <ul
          className={styles.itensContainer}
          style={{
            visibility: selected === categoria.label ? 'visible' : 'hidden',
            height: selected === categoria.label ? 'auto' : 0,
          }}
          key={categoria.label ?? index}
        >
          {categoria?.itens?.map((item: any) => (
            <NavItem key={item.label} value={item} />
          ))}
        </ul>
      ))}
    </div>
  );
};

export default NavItens;
