import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "datetimeCompare",
  label: "Condicional (Data/hora)",
  description: "Compara duas datas/horas",
  category: "Data/hora",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "datetime", name: "datetime1", label: "valor 1" },
      {
        type: "datetimeOperator",
        name: "operator",
        label: "operador",
        hidePort: true,
      },
      { type: "datetime", name: "datetime2", label: "valor 2" },
    ];
  },
  outputs() {
    return [
      { type: "route", name: "route-true", label: "Verdadeiro" },
      { type: "route", name: "route-false", label: "Falso" },
    ];
  },
  async resolveOutputs(inputs) {
    if (inputs.datetime1 == null || !inputs.datetime2 == null)
      return { route: "route-false" };

    switch (inputs.operator) {
      case "equals":
        if (inputs.datetime1 == inputs.datetime2)
          return { route: "route-true" };
        break;
      case "notEquals":
        if (inputs.datetime1 != inputs.datetime2)
          return { route: "route-true" };
        break;
      case "greaterThan":
        if (inputs.datetime1 > inputs.datetime2) return { route: "route-true" };
        break;
      case "lessThan":
        if (inputs.datetime1 < inputs.datetime2) return { route: "route-true" };
        break;
      case "greaterThanOrEqual":
        if (inputs.datetime1 >= inputs.datetime2)
          return { route: "route-true" };
        break;
      case "lessThanOrEqual":
        if (inputs.datetime1 <= inputs.datetime2)
          return { route: "route-true" };
        break;
    }
    return { route: "route-false" };
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({
      result: `[Condicional (Data/hora)]:  Operador 1: ${inputValues.value1}, Operando: ${inputValues.operator}, Operador 2: ${inputValues.value2}`,
      indent,
    });

    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: `(SE verdadeiro)`, indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-false");
    if (nextFalse) {
      stack.push({ result: `(SE falso)`, indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }

    stack.push({ result: `(Fim SE)`, indent });
  },
};

export default instance;
