import CampoCheckbox from '@/components/Formulario/CampoCheckbox';
import CampoSeletor from '@/components/Formulario/CampoSeletor';
import CampoTexto from '@/components/Formulario/CampoTexto';
import Grid, { GridItem } from '@/components/layout/Grid';
import VBox from '@/components/layout/VBox';
import Descricao from '@/components/tipografia/Descricao';
import { useAuth } from '@/lib/useAuth';
import { hasAuthorization, rolesManager } from '@tera/shared/src/lib/auth';
import axios from 'axios';
import { useQuery } from 'react-query';

export const FormChatMensagemAtalho = ({
  value,
  onChange,
  list,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  list: any[];
  fieldErrors: any;
}) => {
  const { user } = useAuth();
  const scopedList = list.filter(it => !!it.global === !!value?.global);

  const newObj = !value?.id || value?.id < 0;
  const maxItemList = newObj ? scopedList.length + 1 : scopedList.length;

  const indices: any[] = Array.from({ length: maxItemList }, (_, i) => ({
    id: (i + 1).toString(),
    label: (i + 1).toString(),
  }));

  const queryGrupos = useQuery({
    queryKey: 'usuario_grupo/box',
    queryFn: async () => {
      const result = await axios.get('/api/usuario_grupo/box');
      return result.data.result;
    },
  });

  const grupoOptions = [
    {
      id: null,
      label: '(Todos)',
    },
    ...(queryGrupos.data?.map((it: any) => ({
      id: it.id,
      label: it.nome,
    })) ?? []),
  ];

  return (
    <Grid>
      <GridItem sm={12} lg={6}>
        <CampoTexto
          value={value?.atalho ?? ''}
          onChange={v => onChange({ ...value, atalho: v })}
          label="Descrição breve"
          error={fieldErrors?.atalho}
        />
      </GridItem>

      <GridItem sm={12} lg={6}>
        <CampoTexto
          value={value?.conteudo}
          onChange={v => onChange({ ...value, conteudo: v })}
          label="Texto"
          error={fieldErrors?.conteudo}
        />
      </GridItem>
      <GridItem sm={12} lg={6}>
        <CampoSeletor
          value={value?.indice}
          onChange={v => onChange({ ...value, indice: v })}
          label="Posição"
          options={indices}
          error={fieldErrors?.indice}
        />
      </GridItem>
      <GridItem sm={12} lg={6}>
        <CampoCheckbox
          label="Visibilidade"
          disabled={!hasAuthorization(user, rolesManager)}
          value={value?.global ?? false}
          onChange={v => onChange({ ...value, global: v })}
          text="Visibilidade global"
          error={fieldErrors?.global}
        />
        <Descricao>
          Atalhos globais são visíveis para todos os atendentes. Atalhos
          pessoais são visíveis apenas para você.
        </Descricao>
      </GridItem>

      <GridItem sm={12} md={6}>
        <CampoSeletor
          label="Grupo"
          value={value?.usuario_grupo_id}
          onChange={v => onChange({ ...value, usuario_grupo_id: +v })}
          options={grupoOptions}
          error={fieldErrors?.usuario_grupo_id}
        />
      </GridItem>
    </Grid>
  );
};
