export const VendaStatusDto = {
  PROSPECT: "prospect",
  QUALIFICADO: "qualificado",
  PROPOSTA: "proposta",
  NEGOCIACAO: "negociacao",
  FECHADA: "fechada",
  PERDIDA: "perdida",
} as const;

export const VendaStatusDtoOptions = [
  { id: VendaStatusDto.PROSPECT, label: "Prospect" },
  { id: VendaStatusDto.QUALIFICADO, label: "Qualificado" },
  { id: VendaStatusDto.PROPOSTA, label: "Proposta" },
  { id: VendaStatusDto.NEGOCIACAO, label: "Negociação" },
  { id: VendaStatusDto.FECHADA, label: "Ganhos" },
  { id: VendaStatusDto.PERDIDA, label: "Perdidos" },
];

export type VendaStatusDtoKeys = keyof typeof VendaStatusDto;
export type VendaStatusDtoType = (typeof VendaStatusDto)[VendaStatusDtoKeys];

export type VendaDto = {
  id: number;
  cliente: { nome: string };
  indice: number;
  status: VendaStatusDtoType;
  valor_total: number;
};
