import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "teraBoundUser",
  label: "Buscar atendente vinculado",
  description: "Retorna o ID do atendente vinculado a este cliente",
  category: "Domínio",
  inputs() {
    return [
      {
        type: "route",
        name: "route",
        label: "Entr. fluxo",
      },
    ];
  },
  outputs() {
    const outputs = [
      {
        type: "number",
        name: "usuarioResponsavelId",
        label: "ID atendente vinculado",
      },
      {
        type: "route",
        name: "route-true",
        label: "Existe atendente vinculado",
      },
      {
        type: "route",
        name: "route-false",
        label: "Sem atendente vinculado",
      },
    ];

    return outputs;
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: `[Buscar atendente vinculado]`, indent });

    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: `(SE Existe atendente vinculado)`, indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-false");
    if (nextFalse) {
      stack.push({ result: `(SE não existe atendente vinculado)`, indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }
    stack.push({ result: `(Fim SE)`, indent });
  },
};

export default instance;
