import CampoSeletor from '@/components/Formulario/CampoSeletor';
import { VendaStatusDtoOptions } from '@tera/shared/src/types/VendaDto';

type CampoSeletorEstadoVendaProps = {
  value: string;
  onChange: (v: string) => void;
  label: string;
  error: string | undefined;
};
export const CampoSeletorEstadoVenda = ({
  value,
  onChange,
  label,
  error,
}: CampoSeletorEstadoVendaProps) => {
  return (
    <CampoSeletor
      options={VendaStatusDtoOptions}
      value={value}
      onChange={onChange}
      label={label}
      error={error}
    />
  );
};
