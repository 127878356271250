import { NodeType } from "../../types/NodeType";

const instance: NodeType = {
  type: "counter",
  label: "Contador",
  description: "Conta quantas vezes o fluxo passou por este nó",
  category: "Fluxo",
  inputs(inputValues) {
    const result = [
      { type: "route", name: "route", label: "Ent. fluxo" },
      { type: "boolean", name: "reset", label: "Zerar contador" },
    ];

    return result;
  },
  outputs() {
    return [
      { type: "number", name: "number", label: "Contador" },
      { type: "route", name: "route", label: "Saída fluxo" },
    ];
  },
  async resolveOutputs(inputs, outConnections, context, localVars) {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: "[Contador]", indent });

    const next = nextNodeId(nodeId, "route");
    if (next) {
      stack.push({ id: next, indent });
    }
  },
};

export default instance;
