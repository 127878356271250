import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "numberCompare",
  label: "Condicional (Número)",
  description: "Compara dois números",
  category: "Número",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "number", name: "value1", label: "valor 1" },
      {
        type: "numberOperator",
        name: "operator",
        label: "operador",
        hidePort: true,
      },
      { type: "number", name: "value2", label: "valor 2" },
    ];
  },
  outputs() {
    return [
      { type: "route", name: "route-true", label: "Verdadeiro" },
      { type: "route", name: "route-false", label: "Falso" },
    ];
  },
  async resolveOutputs(inputs) {
    const v1 = Number(inputs.value1) || 0;
    const v2 = Number(inputs.value2) || 0;

    if (isNaN(v1) || isNaN(v2)) return { route: "route-false" };

    const op = inputs.operator ?? "equals";
    switch (op) {
      case "equals":
        if (v1 == v2) return { route: "route-true" };
        break;
      case "notEquals":
        if (v1 != v2) return { route: "route-true" };
        break;
      case "greaterThan":
        if (v1 > v2) return { route: "route-true" };
        break;
      case "lessThan":
        if (v1 < v2) return { route: "route-true" };
        break;
      case "greaterThanOrEqual":
        if (v1 >= v2) return { route: "route-true" };
        break;
      case "lessThanOrEqual":
        if (v1 <= v2) return { route: "route-true" };
        break;
    }
    return { route: "route-false" };
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({
      result: `[Condicional (Número)]:  Operador 1: ${inputValues.value1}, Operando: ${inputValues.operator}, Operador 2: ${inputValues.value2}`,
      indent,
    });

    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: `(SE verdadeiro)`, indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-false");
    if (nextFalse) {
      stack.push({ result: `(SE falso)`, indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }

    stack.push({ result: `(Fim SE)`, indent });
  },
};

export default instance;
