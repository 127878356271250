import { NodeType } from "../../../types/NodeType";
import { PortType } from "../../../types/PortType";

const instance: NodeType = {
  type: "listAdd",
  label: "Adicionar a lista",
  description: "Adiciona um item no final da lista",
  category: "Lista",
  inputs(inputValues) {
    const result: PortType[] = [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "list", name: "list", label: "Lista", defaultValue: [] },
      { type: "portType", name: "portType", label: "Tipo de item" },
    ];

    if (inputValues?.portType?.id) {
      result.push({
        type: inputValues.portType.id,
        name: "item",
        label: "Item",
      });
    }

    return result;
  },
  outputs() {
    return [
      { type: "list", name: "list", label: "Lista" },
      { type: "number", name: "length", label: "Tamanho da lista" },
      { type: "route", name: "route", label: "Saída fluxo" },
    ];
  },
  async resolveOutputs(inputs, outConnections, context, localVars, globalVars) {
    const underLoop = globalVars.loop != null;
    if (underLoop) {
      const cnt = localVars.loopCount;
      const list = localVars.list ?? [];

      if (cnt === globalVars.loop) {
        return { list, length: list.length };
      }

      const newList =
        globalVars.loop === 0
          ? [...(inputs.list ?? []), inputs.item]
          : [...list, inputs.item];
      localVars.list = newList;
      localVars.loopCount = globalVars.loop;
      return { list: newList, length: newList.length };
    } else if (localVars.list) {
      return { list: localVars.list, length: localVars.list.length };
    } else {
      const list = inputs.list ?? [];
      const newList = [...list, inputs.item];
      return { list: newList, length: newList.length };
    }
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: "[Adicionar a lista]", indent });

    const next = nextNodeId(nodeId, "route");
    if (next) {
      stack.push({ id: next, indent });
    }
  },
};

export default instance;
