import axios from 'axios';

const options = { withCredentials: true };

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export const api = axios.create(options);
