import CampoMultiSeletor from '@/components/Formulario/CampoMultiSeletor';
import { api } from '@/lib/apiCall';
import axios from 'axios';
import { useQuery } from 'react-query';

type CampoSeletorUsuariosProps = {
  value: number[];
  onChange: (v: number[]) => void;
  label?: string;
  error?: string | undefined;
  placeholder?: string;
};
export const CampoSeletorUsuarios = ({
  value,
  onChange,
  label,
  error,
  placeholder,
}: CampoSeletorUsuariosProps) => {
  const q = useQuery('usuario/box', async () => {
    const result = await api.get('/api/usuario/box');
    return result.data.result;
  });
  const options = q.data?.map((c: any) => ({ id: c.id, label: c.nome })) ?? [];

  return (
    <CampoMultiSeletor
      options={options}
      value={value?.map(v => v.toString())}
      onChange={v => onChange(v?.map((v: string) => +v) ?? [])}
      label={label}
      placeholder={placeholder}
      error={error}
    />
  );
};
