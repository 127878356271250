import { mdiChartLine } from '@mdi/js';
import Icon from '@mdi/react';
import styles from './NavCatItem.module.css';

const NavCatItem = ({ value, selected, onClick }: any) => {
  return (
    <li
      onMouseEnter={e => {
        e.preventDefault();
        onClick(value);
      }}
      onMouseDown={e => {
        e.preventDefault();
        onClick(value);
      }}
      onMouseUp={e => {
        e.preventDefault();
      }}
      className={[styles.container, selected ? styles.selected : null].join(
        ' ',
      )}
    >
      <Icon
        path={value.icon}
        size={1.2}
        color="var(--tc-color-brand-primary)"
      />
      <div className="label">{value?.label ?? ''}</div>
    </li>
  );
};

export default NavCatItem;
