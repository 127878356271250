import { NodeType } from "../../types/NodeType";
import { PortType } from "../../types/PortType";
import { NodeValues } from "../../types/NodeValues";

const instance: NodeType = {
  type: "apiCallV2",
  label: "API",
  description:
    "Realiza uma chamada API a um sistema externo e traz os resultados",
  category: "API",
  inputs(inputValues?: NodeValues): PortType[] {
    console.log("API inputs inputValues", inputValues);

    const ret: PortType[] = [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "apiCallV2", name: "api", label: "API", hidePort: true },
    ];

    if (inputValues) {
      const { api } = inputValues;
      if (api) {
        const { url } = api;
        const regexTemplate = /\{\{([^\}]+?)\}\}/g;

        let match;
        while ((match = regexTemplate.exec(url)) !== null) {
          const param = match[1];

          ret.push({ type: "string", name: param, label: param });
        }

        if (api?.api_entradas) {
          for (const entrada of api.api_entradas) {
            ret.push({
              type: entrada.tipo,
              name: entrada.nome,
              label: entrada.nome,
              defaultValue: entrada.valorPadrao,
            });
          }
        }

        if (api?.api_cabecalhos) {
          for (const entrada of api.api_cabecalhos) {
            ret.push({
              type: entrada.tipo,
              name: entrada.nome,
              label: `[header] ${entrada.nome}`,
              defaultValue: entrada.valorPadrao,
            });
          }
        }
      }
    }

    return ret;
  },
  outputs(inputValues) {
    console.log("API outputs inputValues", inputValues);

    const ret: PortType[] = [];

    if (inputValues) {
      const { api } = inputValues;
      if (api?.api_saidas) {
        for (const saida of api.api_saidas) {
          ret.push({
            type: saida.tipo,
            name: saida.nome,
            label: saida.nome,
            defaultValue: saida.valorPadrao,
          });
        }
      }

      ret.push({ type: "route", name: "route-success", label: "Sucesso" });
      ret.push({ type: "route", name: "route-fail", label: "Falha" });
    }

    return ret;
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({
      result: `[API] ${JSON.stringify(inputValues.apiCallV2)}`,
      indent,
    });

    const nextSuccess = nextNodeId(nodeId, "route-success");
    if (nextSuccess) {
      stack.push({ result: "(Se sucesso)", indent });
      stack.push({ id: nextSuccess, indent: indent + 1 });
    }

    const nextFail = nextNodeId(nodeId, "route-fail");
    if (nextFail) {
      stack.push({ result: "(Se falha)", indent });
      stack.push({ id: nextFail, indent: indent + 1 });
    }

    stack.push({ result: "(Fim API)", indent });
  },
};

export default instance;
